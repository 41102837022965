import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, LocalUrl,  Token, config, ImagesUrl} from '../component/include'
import {Banner} from '../component/images'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'


const GalleryList =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [errors, setErrors] = useState({});
    const [gallery, setGallery]= useState({
        title:'',
        status:true
    })
    const [allGallery, setAllGallery]= useState([])
    const handleChange=(event)=>{
      const {name, value} = event.target;
        setGallery({...gallery, [name]:value});
        setErrors({...errors, [name]:''})
    }


    
    const handleSubmit = event =>{
        event.preventDefault();    
    if(!gallery.title){
        setErrors({...errors, title:'Please enter Album title'})
    }else{
        let formData = gallery;
        const fd = new FormData(); 
        for(let k in formData){
            fd.append(k,formData[k]);
        }; 
        setNotice({...notice,  isLoading: true}) 
        fd.append('jwt', Token);
        fd.append('galleryOrder', allGallery.length+1);
        
        fd.append('code', Math.random().toString(36).substr(2,9));
        
           let url = ServerUrl+'/save_controller/tbl_gallery';
          axios.post(url, fd, config)
          .then(response =>{
    window.scrollTo(0,0)
            if (response.data.type ==='success'){
                setErrors({...errors, successMessage: response.data.message})
                
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                }        
                  
          })
          .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        })
        .finally(()=>{
            fetchGallery()
            setNotice({...notice,  isLoading: false}) 
        setGallery({
            title:'',
        status:true
        }) 
    })  
    }      

    } 


    const fetchGallery =()=>{
        setNotice({...notice,   isDataFetching: true}) 

            let sql = "Select ID, code, imageList, galleryOrder, title, coverUrl, status from tbl_gallery "
        
            const fd = new FormData();
            fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>setAllGallery(result.data))
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice,   isDataFetching: false})
            })
    
    }

   
    const TableRecord =(props)=>{
	   
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
                    fd.append('jwt', Token);
                fd.append('newValue', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
             
               let url = ServerUrl+'/updateCustom_controller/tbl_gallery'
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            } 
            const { SearchBar } = Search;
        
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                    <div >
                                <div className="form-group">
        
                            <SearchBar
                                         { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                                         
           
           
      <div className="dt-buttons btn-group pull-right"> 
    
        
    
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=hostel_visitor'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/hostel.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div> 
    </div> 
            </div>
        
            <BootstrapTable
                                     { ...props.baseProps }
                                                 striped
                                             hover
                                             condensed
                                             noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                         pagination={paginationFactory(options) }
                                         cellEdit={ cellEditFactory({
                                           mode: 'dbclick',
                                           blurToSave: true,
                                           autoSelectText: true,
                                 beforeSaveCell: (oldValue, newValue, row, column) => {
                                   if(oldValue !==newValue){
                                   handleUpdate(column, newValue, row.ID);
                                   
                                  return true;
                                 }
                                 
                               }
                                         }) }
                                     />
                    </div>
                    )
                            }
        </ToolkitProvider>
        }
        


     
    const handleDelete = (ID, table)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID+table)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token);
           fd.append('ID', 'ID')
            fd.append('data', ID)
    
            let url = ServerUrl+'/delete_controller/'+table
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               setNotice({...notice, isLoading: false })
           }) 
    }



    
   
    const handleAction = (cell, row)=>{
        return <React.Fragment>     
        
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.ID}`} className='btn btn-danger btn-sm'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>
        
        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.ID, 'tbl_gallery')} mID={row.ID} /> 
        </React.Fragment>  
    }
    
      const countImage =(list)=>{
        let count = 0;
        if(list!=='[]'){
            count = JSON.parse(list).length
        }
        return count
      }              
    const tableHeader = [
    {dataField: 'ID', text: 'Action', formatter:handleAction, editable: false},
    {dataField: 'coverUrl', text: 'Image', editable: false, formatter:(cell, row)=><img id={cell} className="image" onError={(e)=>{e.target.onerror = null; e.target.src=Banner}} src={`${ImagesUrl}/gallery/${cell}`}  style={{display:'block',height:'50px',width:'50px'}} title="Cover"  alt="Cover" />},
    {dataField: 'title', text: 'Title', sort:true, editor: {
        type: Type.TEXTAREA
      }, formatter:(cell, row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} >{cell}</div>},
      {dataField: 'galleryOrder', text: 'Order', sort:true},
    {dataField: 'status', text: 'Status', sort:true, editor: {
        type: Type.SELECT, options:[{value:true, label:'Active'},{value:false, label:'Inactive'}]
      }, formatter:(cell, row)=>row.status==='true'? <div className="pcoded-badge label label-success">ACTIVE</div>: <div className="pcoded-badge label label-danger">INACTIVE</div>},
    {dataField: 'imageList', text: 'Upload', sort:true, formatter:(cell, row)=><a href={"/cms/gallery/add_image/"+row.code} style={{color:'blue'}} title="Add Image" target="new">Uploaded ({countImage(cell)})</a>, editable: false}
     ];

useEffect(()=>{
    fetchGallery();
},[]);


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
               
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Gallery">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-image"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Gallery</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Gallery Albums</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

         
                <div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Add Album</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
            
<section className="col-md-12">
   

   <div className="form-group">
<label >Album Title</label>
<textarea type="text" rows="3" className={errors.title ? 'form-control form-control-danger' : 'form-control'} name="title"  onChange={handleChange} value={gallery.title} >{gallery.title}</textarea>
<span style={{color:'red'}}>{errors.title && errors.title}</span>
   </div>

</section>


<section className="col-md-12">
    <label>Status</label>
                                        
        
<div className="border-checkbox-section">
                    
            <div className="border-checkbox-group border-checkbox-group-primary">
                <input className="border-checkbox" type="checkbox" onChange={()=>setGallery({...gallery, status : !gallery.status})} name="status" value={gallery.status} checked={gallery.status} id="status" />
                        <label className="border-checkbox-label" htmlFor="status">Active</label>
                    </div>
                    
                    </div> 

                    </section>

</div>

<hr/>

   <footer className="pull-right">
   
   <button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success "><i className="fa fa-save"></i> Save</button>
            </footer>
 </div> 
							
</div>

</div>  
</div>  </div>
							
           </div>
                            
       </div></div>


                <div className="row  ">

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Album List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="col-md-12">
        <div className="table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allGallery}  columns={tableHeader}  />} 

    </div></div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(GalleryList) 