import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../../component/notify'
import PageHeader from '../../component/pageheader'
import {ServerUrl,  Token, config} from '../../component/include'
import { shortText, timeSince } from '../../component/globalFunction'

import { Link } from 'react-router-dom'
import { usePaginated, DOTS } from '../../component/usePagination';

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../../component/authentication'
import Error403 from '../../settings/error403'

const Tickets =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDatafetching: false,
    department:'all'
})
    
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true); 
  const [content, setContent] = useState([]);
    const [tickets, setTickets]= useState([])    
    
    const [stat, setStat]= useState({

        isNew:0,
        closed:0,
        total:0,

        admin:0,
        finance:0,
        ict:0,
        support:0,
        security:0
    }) 
	const [totalPost, setTotalPost] = useState([]);
    const [perPage, setPerPage] = useState(16);
    
    let [page, setPage] = useState(0);
    const PER_PAGE = Number(perPage);
    
    const count = Math.ceil(content.length / PER_PAGE);
    
    
    
    const [filterText, setFilterText] = useState('');
    
    
    const filteredItems =(event)=>{
        var text= event.target.value
        setFilterText(text)
        
        let item = tickets.filter(item => item.ticketID.toLowerCase().includes(text.toLowerCase())
                                    || item.senderName.toLowerCase().includes(text.toLowerCase()) 
                                    || item.subject.toLowerCase().includes(text.toLowerCase())
            );
        if(text!==''){
            setContent(item)
        }else{
            setContent(tickets)
        }
            
        
             }
    const nextPage = (num)=>{
        setPage(num)
        fetchPost(num, notice.status)
        }

        const fetchAll = ()=>{
            setPage(0)
  fetchPost(0, 'all')
            }

const fetchTickets = async()=>{

    var sql ="Select (SELECT count(ID) from tbl_tickets where messageType ='main') AS total, (SELECT count(ID) from tbl_tickets where messageType ='main' and isNew ='Yes' ) AS isNew, (SELECT count(ID) from tbl_tickets where messageType ='main' and isNew ='Yes' and department ='IT Center' ) AS ict, (SELECT count(ID) from tbl_tickets where messageType ='main' and isNew ='Yes' and department ='Admins' ) AS admins, (SELECT count(ID) from tbl_tickets where messageType ='main' and isNew ='Yes' and department ='Security' ) AS security,  (SELECT count(ID) from tbl_tickets where messageType ='main' and isNew ='Yes' and department ='Finance' ) AS finance, (SELECT count(ID) from tbl_tickets where messageType ='main' and isNew ='Yes' and department ='Support' ) AS support"
     
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller'
    
  await  axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){
            setStat({
                isNew:result.data[0].isNew,
                total:result.data[0].total,

                admin:result.data[0].admins,
                finance:result.data[0].finance,
                ict:result.data[0].ict,
                support:result.data[0].support,
                security:result.data[0].security,
            })
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
}




const handleCheckOne =(e)=>{  
    
    let {name, value} = e.target

    const newContent = [...content]
    const otherContent = newContent.filter(item=>item.code!==name)
    const custom = newContent.filter(item=>item.code===name)
    custom[0].ticketStatus = value==='true'?'false':'true'
    const currentContent = custom.concat(otherContent)
   setContent(currentContent.sort((a, b) =>(new Date(a.lastModified) > new Date(b.lastModified)) ? -1 : 1)) 
        
       }
    



const fetchPost =  (skip, department)=>{

var offset = Number(skip)*16
    var sql ="Select ID, code, subject, senderType, lastModified, senderName, dateTime, dateCreate, fileUrl, ticketID, status, isNew, department, 'false' as ticketStatus from tbl_tickets where messageType ='main'  "

    if(department !== 'all'){

        sql = sql + " and department ='"+department+"'"
       }

sql = sql + " ORDER BY lastModified DESC LIMIT "+perPage+" OFFSET "+offset


    setNotice({...notice, isDatafetching:true})

    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller'
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){
         setContent(result.data.sort((a, b) =>( new Date(a.lastModified) > new Date(b.lastModified)) ? -1 : 1))
         setTickets(result.data.sort((a, b) =>(new Date(a.lastModified) > new Date(b.lastModified)) ? -1 : 1))
        }else{
            setContent([])
         setTickets([])
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{

        setNotice({...notice, isDatafetching:false, status:department})
             }) 

            }

       
        const Pagination = () => {
                
        const pageSize = perPage;
        const totalCount = stat.total;
        const siblingCount = 1
        const currentPage = page
        
        const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
        
        
        let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
        return (

<div className="row">
    
    <div className="col-xs-12 col-sm-12 col-md-5">
        
        <div className="dataTables_info" id="order-table_info" role="status" aria-live="polite">Showing {page==0?page+1:page+16} to {page==0?content.length:content.length+16} of {stat.total} entries</div></div>
        
        <div className="col-xs-12 col-sm-12 col-md-7 pull-right">
    
    
        <nav className="" aria-label="Page navigation sample">
            <ul  className="pagination"  >
            <li className={currentPage === 0 ? ' page-item disabled' : 'page-item'} >

            <a className="page-link" href="#!" onClick={() =>nextPage(page - 1)}>
                
                
            <span aria-hidden="true">«</span>
                    <span className="sr-only">Previous</span>
               </a>
            </li>
            {paginationRange&&paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                return <li className="pagination-item dots" key={index}>
                <a className="page-link" href="#!" >&#8230;</a>
                </li>
                }
                return (
                <li className={pageNumber-1 === currentPage?' page-item  active':'page-item '}  key={index}>
                    <a className="page-link" href="#!" onClick={() =>nextPage(pageNumber-1)} >{pageNumber}</a>
                    
                </li>
                );
            })}
        
        <li  className={currentPage === lastPage-1  ? ' page-item disabled' : 'page-item'}>
        <a className="page-link" href="#!" onClick={() =>nextPage(page + 1)} aria-label="Next">
            
        <span aria-hidden="true">»</span>
                    <span className="sr-only">Next</span>
           </a>
            </li>
        
            </ul> </nav>
    
    </div></div>

        );
        
            }

            const handleOpenClosed =(status)=>{
      
                let dataId = []
                var newContent = [...content]
                for (var i in newContent){
                    if(newContent[i].ticketStatus==='true'){
                        dataId.push(newContent[i].code)
                        
                    }
                }
                if(dataId.length===0){
                    Alerts('Info!', 'info', 'Please select at least one record')
                      
                }else{
                    setNotice({...notice, isLoading: true}) 
                var data = JSON.stringify(dataId, null, 2)
            
                    const fd = new FormData();
                    fd.append('data', data)
                    fd.append('status', status)
                    fd.append('jwt', Token)
                let url = ServerUrl+'/update_controller/tbl_tickets';
                      axios.post(url, fd, config)
                      .then(response =>{
                       if(response.data.type ==='info'){
                          Alerts('Info!', 'info', response.data.message)
                          
                              } else{
                          Alerts('Error!', 'danger', response.data)
                              }   
                      })
                      .catch((error)=>{
                        Alerts('Error!', 'danger', error.message)
                      }).finally(()=>{
                          setNotice({...notice,   isLoading: false
                          })
                         
                        fetchPost(0, 'all')
                        fetchTickets()
                      }) 
                    }
            }
            


            const handleDelete =()=>{
          
                let dataId = []
                var newContent = JSON.parse(JSON.stringify(content));
                for (var i in newContent){
                    if(newContent[i].ticketStatus==='true'){
                        dataId.push(newContent[i].ticketID)
                        
                    }
                }
                let close =   document.getElementById('btnWarningDialog-delAll')
                if(dataId.length===0){
                    Alerts('Error!', 'danger', 'Please select at least one ticket to delete')
                      
                }else{
                    setNotice({...notice, isLoading: true}) 
                var data = JSON.stringify(dataId, null, 2)
        
                    const fd = new FormData();
                    fd.append('data', data)
                    fd.append('column', 'ticketID')
                    fd.append('jwt', Token)
                let url = ServerUrl+'/deleteTickets_controller.php?tablename=tbl_tickets';
                      axios.post(url, fd, config)
                      .then(response =>{
                       if(response.data.type ==='success'){
                          Alerts('Default!', 'default', response.data.message)
                          
                              } else{
                          Alerts('Error!', 'danger', response.data)
                              }   
                      })
                      .catch((error)=>{
                        Alerts('Error!', 'danger', error.message)
                      }).finally(()=>{
                          setNotice({...notice,  isLoading: false })
                          fetchPost(0, 'all')
                          fetchTickets()
                      }) 
                    }
                    close.click();
            }


useEffect(()=>{
    setIsAuthenticated(verifyRouter(location.pathname))  
    fetchTickets()
  fetchPost(0, 'all')
},[]);



return (<>
  
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Tickets">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bar-chart"></i> </a>
                            </li>
                           
                            <li className="breadcrumb-item"><a href="#!">Communication</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Tickets</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
              

                <div className="card">
<div className="card-block email-card">
    <div className="row">
        <div className="col-lg-12 col-xl-3">
            <div className="user-body">
                <div className="p-20 text-center">
                    
<Link to="/communication/tickets/create" className="btn btn-success "> <i className="fa fa-chat"></i> Create New Ticket</Link>
                   
                </div>
                <ul className="page-list nav nav-tabs flex-column">
                    <li className="nav-item mail-section">
                        <a className={`nav-link ${notice.department==='all'?'active':''}`} data-toggle="pill" href="#" onClick={fetchAll} >
                            <i className="icofont icofont-inbox"></i> All Tickets
                        </a>
                    </li>
                    <li className="nav-item mail-section">
                        <a className={`nav-link ${notice.department==='IT Center'?'active':''}`} data-toggle="pill" href="#" onClick={()=>fetchPost(page, 'IT Center')} >
                            <i className="fa fa-university"></i> IT Center
                            {stat.ict!=='0'? <span className="label label-primary f-right">{stat.ict}</span>:''}
                        </a>
                    </li>
                    <li className="nav-item mail-section">
                        <a className={`nav-link ${notice.department==='Admins'?'active':''}`} onClick={()=>fetchPost(page, 'Admins')} data-toggle="pill" href="#" >
                            <i className="fa fa-folder-open"></i> Admins
                            {stat.admin!=='0'? <span className="label label-primary f-right">{stat.admin}</span>:''}
                        </a>
                    </li>
                    <li className="nav-item mail-section">
                        <a className={`nav-link ${notice.department==='Finance'?'active':''}`} onClick={()=>fetchPost(page, 'Finance')} data-toggle="pill" href="#" >
                            <i className=" fa fa-folder"></i> Finance
                            
                          {stat.finance!=='0'?  <span className="label label-info f-right">{stat.finance}</span>:''}
                        </a>
                    </li>

                    <li className="nav-item mail-section">
                        <a className={`nav-link ${notice.department==='Support'?'active':''}`} onClick={()=>fetchPost(page, 'Support')} data-toggle="pill" href="#" >
                            <i className=" fa fa-sitemap"></i> Support
                            
                          {stat.support!=='0'?  <span className="label label-info f-right">{stat.support}</span>:''}
                        </a>
                    </li>


                    <li className="nav-item mail-section">
                        <a className={`nav-link ${notice.department==='Security'?'active':''}`} onClick={()=>fetchPost(page, 'Security')} data-toggle="pill" href="#" >
                            <i className=" fa fa-fax"></i> Security
                            
                          {stat.security!=='0'?  <span className="label label-info f-right">{stat.security}</span>:''}
                        </a>
                    </li>


                </ul>
                <ul className="p-20 label-list">
                    <li>
                        <h5>Status</h5>
                    </li>
                    <li>
                        <a className="mail-family" href="#">Open</a>
                    </li>
                    <li>
                        <a className="mail-friends" href="#">Answered</a>
                    </li>
                    <li>
                        <a className="mail-design" href="#">Reply</a>
                    </li>
                    <li>
                        <a className="mail-office" href="#">Closed</a>
                    </li>
                   
                </ul>
            </div>
        </div>


        <div className="col-lg-12 col-xl-9">
            <div className="tab-content" id="pills-tabContent">



                <div className="tab-pane fade show active" id="e-inbox" role="tabpanel">

                <WarningModal message='Are you really sure?' handleAction={handleDelete} mID={'delAll'} /> 
                    <div className="mail-body">
                        <div className="mail-body-header">
                            <button type="button" onClick={()=>handleOpenClosed('Closed')} className="btn btn-warning btn-xs waves-effect waves-light">
                                    <i className="icofont icofont-folder"></i> Close Selected
                                </button>
                            <button type="button" className="btn btn-danger btn-xs waves-effect waves-light" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`}>
                                    <i className="icofont icofont-ui-delete"></i> Delete Selected
                                </button>
                           {/*  <div className="btn-group dropdown-split-primary">
                                <button type="button" className="btn btn-info dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="icofont icofont-ui-folder"></i>
                                    </button>
                                <div className="dropdown-menu" x-placement="bottom-start" style={{position: 'absolute', transform: 'translate3d(2px, 42px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
                                    <a className="dropdown-item waves-effect waves-light" href="#">Action</a>
                                    <a className="dropdown-item waves-effect waves-light" href="#">Another action</a>
                                    <a className="dropdown-item waves-effect waves-light" href="#">Something else here</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item waves-effect waves-light" href="#">Separated link</a>
                                </div>
                            </div> */}

                            <form className="f-right">
                                <div className="right-icon-control">
                                    <input type="text" className="form-control" name="filterText" value={filterText} onChange={filteredItems} placeholder="Search tickets"  />
                                    <div className="form-icon">
                                        <i className="icofont icofont-search"></i>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className="mail-body-content">
                            <div className="table-responsive">
                              
                              
                              
                                <table className="table">
                                    <tbody>
                                    {notice.isDatafetching?<TableLoader/>:content.length!==0?content.map((item, index)=> <tr key={index} className={item.status==='reply'||item.isNew ==='Yes'?"unread":"read"}>
                                        <td>
                                            <div className="check-star">
                                                <div className="checkbox-fade fade-in-primary checkbox">
                                                    <label>
                                                            <input type="checkbox"  value={item.ticketStatus} name={item.code}  checked={item.ticketStatus==='false'?false:true} onChange={(e)=>handleCheckOne(e)} />
                                                            <span className="cr"><i className="cr-icon icofont icofont-verification-check txt-primary"></i></span>
                                                        </label>
                                                </div>
                                                <i className={`icofont icofont-star 
                                                ${item.status==='Open'?
                                                'text-success':item.status==='Closed'?
                                                'text-danger':item.status==='Answered'?
                                                'text-warning':'text-primary'} `}></i>
                                            </div>
                                        </td>
                                        <td onClick={()=>window.open("/communication/ticket/reply/"+item.ticketID, '_self')}><a href={"/communication/ticket/reply/"+item.ticketID} className="email-name">{'#'+item.ticketID +' - '+ shortText(item.senderName, 15)}</a></td>
                                        <td onClick={()=>window.open("/communication/ticket/reply/"+item.ticketID, '_self')}><a href={"/communication/ticket/reply/"+item.ticketID} className="email-name">{shortText(item.subject, 60)}</a></td>
                                        <td className="email-attch" onClick={()=>window.open("/communication/ticket/reply/"+item.ticketID, '_self')} >{String(item.fileUrl) !== "[]"?
                                            
                                            <a href={"/communication/ticket/reply/"+item.ticketID}><i className="icofont icofont-clip"></i></a>:''}
                                        
                                        </td>

                                        <td className="email-time" onClick={()=>window.open("/communication/ticket/reply/"+item.ticketID, '_self')}>{timeSince(new Date(item.lastModified))}</td>
                                    </tr>

                    ):<span>No record found</span>}

                                  
                                </tbody></table>

{content.length!==0?
<Pagination />:''}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</div>




   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>
            </>
 );
        
}

export default React.memo(Tickets) 