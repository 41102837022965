import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'
 
const LogOut =()=> {

    const clearCookies=()=>{
        Cookies.remove('kaustnm')
         Cookies.remove('kauscd')	
      Cookies.remove('kagpcd')
      Cookies.remove('kamtk')
      Cookies.remove('karfsh')
      localStorage.setItem('_expt', Date.now())
      localStorage.setItem('_bexpt', Date.now())
    }

 useEffect(()=>{  
  clearCookies()
  window.open("/", '_self')
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default LogOut