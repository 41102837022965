import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token, config, schoolName} from '../component/include'

import Select from 'react-select'
import { formatGroupLabel, longDate} from '../component/globalFunction'

const DisplayStudentAttendance =()=> {
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState([])
    const [sqlQuery, setSqlQuery] = useState([]);
    const [subject, setSubject]= useState([])
    const [schoolInfo, setSchoolInfo]=useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [head, setHead]= useState([])
    const [schoolClass, setSchoolClass]=useState([]);
    const [staff, setStaff]= useState([])
    const [attendance, setAttendance] = useState({
       staff:[],
       sectionID:[],
        subject:[],
        attendanceType:'fullday',
        attendanceMonth:'',
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'},
    })

    const fetchSchoolInfo =()=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }

    const  handleFetchClass =()=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
         }
       
         const fetchStaff =()=>{
            const fd = new FormData();
            fd.append('jwt', Token); 
            let url= ServerUrl+'/fetch_controller/tbl_staff'
            axios.post(url, fd, config).then(result=>setStaff(result.data))
        }
    
        const fetchSubject =()=>{
            const fd = new FormData();
            fd.append('jwt', Token); 
            let url = ServerUrl+'/fetch_controller/tbl_subject'
            axios.post(url, fd, config).then(result=>setSubject(result.data))
        }

        
        const handleSelect= (option, action)=>{

            setAttendance({...attendance, [action.name]:option});
            setErrors({...errors, [action.name]:''})
            setResult('')
        }

        const handleChange=(event)=>{
            const {name, value} = event.target;
            setAttendance({...attendance, [name]:value})

            if(name==='attendanceMonth'){
            const days =  new Date(value.slice(0,4), value.slice(5,7), 0).getDate();
            const start = value+'-01';
            const end = value+'-'+days;
            getDaysBetween(start, end)
            }
            setResult('')
            setErrors({...errors, [name]:''})
        }
       
        const getDaysBetween =(startDate, endDate)=>{
            const start = new Date(startDate)
            const end =  new Date(endDate)
        
            var result = []
            while (start <= end){
                result.push(new Date(start).toISOString().slice(0,10))
                start.setDate(start.getDate()+1)
            }
    
            let sqlString = result.map(data=>"max(case when a.attendanceDate  = '"+data+"'  then a.indicator else '-' end) AS '"+data.slice(8,10)+getDays(data)+"'")
            setHead(result.map(data=>data.slice(8,10)+getDays(data)))
    
            let final =   'SELECT  s.admissionNumber, s.studentName, s.sectionID, sum(case when a.indicator  = "P" then 2 else 0 end) as P, sum(case when a.indicator  = "H" then 1 else 0 end) as H, sum(case when a.indicator  = "A" then 2 else 0 end) as A, '+String(sqlString)+ ' from tbl_students s, tbl_student_attendance a where   s.code = a.studentCode and a.attendanceDate >= "'+startDate+'" and a.attendanceDate <= "'+endDate+'" and a.sectionID="'+attendance.sectionID.value+'"'

            setSqlQuery(final)
        }
        
        const getDays=(date)=>{
            let day;
            switch (new Date(date).getDay()){
                case 0:
                    day =" Sun";
                    break;
                case 1:
                    day =" Mon";
                    break;
                case 2:
                    day =" Tue";
                   break;
                case 3:
                    day =" Wed";
                        break;
                case 4:
                    day =" Thu";
                        break;
                case 5:
                    day =" Fri";
                    break;
                case 6:
                    day =" Sat"
                    break;
                default:
                    day=" Sun"
            }
                return day
            }
    
            const fetchResult =()=>{
                if(attendance.sectionID.length===0){
                    setErrors({...errors, sectionID:'Please select Student Class'})
                }else if(attendance.attendanceMonth===''){
                    setErrors({...errors, attendanceMonth:'Please select attendnace month'})
                
                }  else{
                
                
                    var sql = sqlQuery

            if(attendance.staff.length!==0){
                sql = sql + '  and a.staff = "'+attendance.staff.value+'"'
            }
            if(attendance.subject.length!==0){
                sql = sql + '  and a.subject = "'+attendance.subject.value+'"'
            }

            sql = sql+ ' and a.attendanceType = "'+attendance.attendanceType+'" group by a.studentCode '+attendance.orderBy.value
            
            
  setNotice({...notice, isLoading: true}) 
            const fd = new FormData();
            fd.append('jwt', Token); 
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{
                if(result.data.length!==0){
                    setResult(result.data.filter(item=>item.sectionID===attendance.sectionID.value))
                }else{
                    Alerts('Info!', 'default', 'No record found for the selected criterials')
                }
                
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            })  
        } 
            }
    
     
     useEffect(()=>{
        fetchSchoolInfo()
        handleFetchClass()
        fetchStaff()
        fetchSubject()
    },[]);


        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Display Student  Attendance">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Student Attendance</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Display Student Attendance</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
            
        <section className="col-md-12">
        <span className="pcoded-badge label bg-simple-c-pink">Color Icon For Absent</span>
        
        <span className="pcoded-badge label label-info">Color Icon For Half Day</span>
        <span className="pcoded-badge label bg-simple-c-green">Color Icon For Present</span>
        </section>


        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
        <section className="col-md-6">
            <div className="form-group">
          <label> Student Class<span style={{color:'red'}}>*</span></label>
          <Select  options={
                             schoolClass.map((list, idx)=> {
                                return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                         } 
                         formatGroupLabel={formatGroupLabel}
 onChange={handleSelect} className={errors.sectionID ? 'form-control form-control-danger' : ''} name="sectionID" value={attendance.sectionID}  /> <span style={{color:'red'}}>{errors.sectionID && errors.sectionID}</span>
             </div>

      </section>
      
    {/*   

<section className="col-md-6">
            <div className="form-group">
          <label> Order</label>
          <Select  options={[
             {value:'order by s.studentName ASC', label:'order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelect}  name="orderBy" value={attendance.orderBy}  /> 

 
             </div>
      
      </section> */}
      <section className="col-md-6">
      <div className="form-group">
   <label >Attendance Month</label>
   <input type="month" className={errors.attendanceMonth ? 'form-control form-control-danger' : 'form-control'} name="attendanceMonth"  placeholder='YYYY-MM e.g 2022-06' maxLength={7}  onChange={handleChange} value={attendance.attendanceMonth} />

   <span style={{color:'red'}}>{errors.attendanceMonth && errors.attendanceMonth}</span>
               </div>

   

</section>{/* 

<section className="col-md-6">
<div className="form-group">
                    <label >Type</label>
                    
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={attendance.attendanceType ==="fullday"} onChange={handleChange} value="fullday" name="attendanceType" />
										<i className="helper"></i>Full Day</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={attendance.attendanceType ==="subject"} onChange={handleChange} value="subject" name="attendanceType"  />
										<i className="helper"></i>Subject Wise</label></div>

                    </div>
                    
                    </div>

</section>		
      <section className="col-md-6" style={{display:attendance.attendanceType ==="fullday"?'none':'block'}}>


      <div className="form-group">
						<label >Subject<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={attendance.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	
			</div>
      
      </section>

      <section className="col-md-6" style={{display:attendance.attendanceType ==="fullday"?'none':'block'}}>
            <div className="form-group">
          <label> Staff</label>
          <Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.mobile, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.staffName}`}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={attendance.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
 
             </div>
      
      </section> */}

				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchResult} className="btn btn-primary "><i className="fa fa-search"></i> Search Attendance</button>
								</footer>		</section>		
                                </div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>



{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
                       <div className="row" >

<table className="table  table-full-width">
       <tbody>
                <tr>
                <td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                     
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Student Attendance </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{attendance.sectionID.label}</b></td>
                    <td >Month: <b>{attendance.attendanceMonth}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>S/N</th>
                <th>Admission No</th>
                    <th className='fixed-column'>Student Name</th>
                    <th>P</th>
                    <th>H</th>
                    <th>A</th>
                     {head&&head.map((data, id)=><th key={id} >{data}</th>)} 
                </tr>
              </thead>
              <tbody>
              {result.length!==0?result.map((st, idx)=> 
                <tr key={idx}>
                <td>{idx+1}</td>
                <td >{st.admissionNumber}</td>
                    <td className='fixed-column'>{st.studentName}</td>
                <td>{st.P}</td>
                <td>{st.H}</td> 
                    <td>{st.A}</td>            
                    {head&&head.map((data, id)=><td key={id} >
                    {st[data]==='P'?
                    <span className="pcoded-badge label label-success">{st[data]}</span>:
                    st[data]==='A'?
                    <span className="pcoded-badge label label-danger">{st[data]}</span>: 
                    st[data]==='H'?
                    <span className="pcoded-badge label label-info">{st[data]}</span>:
                    st[data]} </td>)} 
                </tr>):[]}
              </tbody>

        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/staff_attendance_excel.php'} target='_blank'>
                <input type='hidden' name='head' value ={JSON.stringify(head, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='api' value ='student' />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-table"></i> Export to Excel</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(DisplayStudentAttendance) 