import React, {useEffect} from 'react';
import {Alerts} from './component/notify'
import 'react-notifications-component/dist/theme.css'

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import BasicDetails from './setup/basic_details';
import Dashboard from './setup/dashboard'
import Software_details from './setup/software_details'
import Session from './setup/session'
import Student_class from './setup/student_class'
import Subject from './setup/subject'


import FeeCharge from './fees/feeCharge'
import Fee_type from './fees/fee_type'
import Class_fee from './fees/class_fees'
import StudentFees from './fees/student_fees'
import Fee_reminder from './fees/fee_reminder'
import UpdateStudentFees from './fees/update_student_fees'
import FeesPayment from './fees/fees_payment'
import PrintVoucher from './fees/print_voucher'
import DateWiseReport from './fees/date_wise_fees'
import ClassFeesSChedule from './fees/print_class_schedule'
import FeeRegister from './fees/student_fee_register'
import PaymentHistory from './fees/payment_history'
import FamilyFees from './fees/family_fees'

import StudentAdmission from './students/student_admission'
import ViewStudents from './students/view_students'
import MasterEntry from './students/master_entry'
import UploadStudents from './students/upload_students'


import BulkStudents from './students/bulk_registration'
import Transfer from './students/transfer_class'
import Promotion from './students/promotion'
import Family from './students/define_family'
import StudentReview from './students/student_review'
import StudentProfile from './students/profile'

import HouseSummary from './students/house_summary'
import ClubSummary from './students/club_summary'
import FamilySummary from './students/family_summary'
import ClassList from './students/class_list'
import ClassReportSummary from './students/class_summary'
import HouseAddress from './students/student_addresses'

import Login_report from './students/login_report';
import Change_password from './students/change_password';


import AccountType from './finance/account_type'
import CreateAccount from './finance/create_account'
import AccountHead from './finance/account_head'
import BulkIncome from './finance/bulk_income'
import RecordIncome from './finance/record_income'
import RecordExpenses from './finance/record_expenses'

import Bulk_expenses from './finance/bulk_expenses';
import UploadExpenses from './finance/upload_expenses'
import UploadIncome from './finance/upload_income'
import AccountStatement from './finance/account_statement'
import DailyRegister from './finance/daily_register'


import BulkStaff from './staff/bulk_registration'
import StaffSettings from './staff/settings'
import StaffRegistration from './staff/staff_registration'
import ViewStaff from './staff/view_staff'
import StaffProfile from './staff/staff_profile'
import UploadStaff from './staff/upload_staff'
import StaffReview from './staff/staff_review'
import ClassTeacher from './staff/assign_class_teacher'
import UserGroup from './staff/groups';
import UserRoles from './staff/roles';
import LoginReportStaff from './staff/login_report';
import Staff_conference from './staff/staff_conference';
import Changestaffpassword from './staff/change_password';

import MySignature from './staff/signature';
import UsertypePermission from './users/usertype_permission'
import ViewUsers from './users/view_users'


import LibrarySettings from './library/settings'
import ManageBooks from './library/manage_books'
import BookList from './library/book_list'
import UploadBooks from './library/upload_books'
import BookBarcode from './library/book_barcode'
import IssuesReturn from './library/issues_return'
import IssuesReturnStaff from './library/issues_returnStaff'
import BookIssueReport from './library/book_issue_report'
import Bulk_register from './library/bulk_register';
import BookIssueReportStaff from './library/book_issue_report_staff'
import Books from './library/books'
import ELibrary from './library/e-library'



import ExamStandard from './exam/standards'
import ExamSettings from './exam/settings'
import ResultSetup from './exam/result_setup'
import ResultEntry from './exam/result_entry'
import AssessmentEntry from './exam/assessment_entry'
import SubjectMark from './exam/subject_mark'
import ClassMark from './exam/class_mark'
import SectionMark from './exam/section_mark'
import ResultSetupUpdate from './exam/update_class_result'
import MockResultEntry from './exam/mock_result_entry'


import ViewResult from './exam/view_results'

import ViewMidResult from './exam/half-term_result'
import ResultComment from './exam/result_comment'


import ExamSchedule from './exam/exam_schedule'
import Transcript from './exam/transcript'
import Result_attendance from './exam/result_attendance';
import Exam_chart from './exam/exam_chart';
import ClassSubject from './exam/class_subject'
import SubjectTeacher from './exam/assign_subject_teacher'


import StaffAttendance from './attendance/staff_attendance'
import UploadStaffAttendance from './attendance/upload_staff_attendance'
import DisplayStaffAttendance from './attendance/display_staff_attendance'
import StudentAttendance from './attendance/student_attendance'
import DisplayStudentAttendance from './attendance/display_student_attendance'
import StudentLateArrival from './attendance/student_arrival_departure_late'
import StudentAttendanceSummary from './attendance/student_attendance_summary'
import StaffAttendanceSummary from './attendance/staff_attendance_summary'
import StaffWorkinHours from './attendance/staff_working_hours'





import PayrollSettings from './payroll/settings'
import SalaryStructure from './payroll/salary_structure'
import AssignSalaryStructure from './payroll/assign_salary_structure'
import SalaryPayment from './payroll/salary_payment'
import ApplyLeave from './payroll/apply_leave'
import ApproveLeave from './payroll/approve_leave'
import Payslip from './payroll/payslip'
import StaffSalary from './payroll/staff_salary'


import Setup from './timetable/setup'
import TimetableSettings from './timetable/settings'
import DefineHolidays from './timetable/define_holidays'
import HolidaysList from './timetable/holidays_list'
import TimetableByDate from './timetable/timetableByDate'
import TimetableByDay from './timetable/timetableByDay'
import StaffSubjectAllocation from './timetable/staff_subject_allocation'
import StaffSchedule from './timetable/staff_schedule'
import AssignSubjectTeacher from './timetable/assign_subject_teacher'
import ViewTimetable from './timetable/view_timetable'
import ViewSubjectPlan from './timetable/view_subject_plan'


import AdmissionSettings from './admission/settings'
import StartAdmission from './admission/start_admission'
import AdmissionRequest from './admission/admission_request'



import Gallery from './gallery/gallery_list'
import GalleryList from './gallery/image_list'


import SendMessage from './communication/send_message'
import SendStaffMessage from './communication/send_message_staff'
import MessageReport from './communication/message_report'
import SendLoginDetails from './communication/send_login_details'
import SendStaffLoginDetails from './communication/send_login_details_staff'
import Noticeboard from './communication/noticeboard'
import Birthdays from './communication/upcoming_birthday'
import Template from './communication/template/template'
import EditTemplate from './communication/template/editTemplate'

import Ticket from './communication/tickets/tickets';
import ReplyTicket from './communication/tickets/replyticket';
import EmailApi from './communication/emailApi';
import SmsApi from './communication/smsApi';


import ErrorLog from './settings/errorList';
import PaymentGateway from './settings/paymentGateway';
import Sitemap from './settings/sitemap';
import Navigation from './settings/navigation';
import LoginReport from './users/login_report'

import Backup from './settings/backup';
import Restore from './settings/restore';


import Error404 from './settings/error404';

import AddUsers from './users/addUser';
import EditUsers from './users/editUsers';
import Users from './users/users';
import UserProfile from './users/user_profile';

import axios from 'axios'
import { SessionTimeout } from './component/notify'
import Cookies from 'js-cookie'
import {config, ServerUrl, staffCode, staffName, Token} from './component/include'
import {encrypt, decrypt} from './component/authentication'



import Create_route from './transport/create_route'
import Vehicle from './transport/vehicle'
import Create_destination from './transport/create_destination'
import Assign_student from './transport/assign_student';

import Hostel from './hostel/hostel';
import Hostel_allocation from './hostel/hostel_allocation';
import Hostel_application from './hostel/hostel_application';
import Transfer_student from './hostel/transfer_student';
import Room_availability from './hostel/room_availability';
import Room_occupant from './hostel/room_occupant';
import Room_payment from './hostel/room_payment';
import Hostel_visitors from './hostel/hostel_visitors';
import Hostel_chart from './hostel/hostel_chart';
import Online_payment_history from './fees/online_payment_history';
import Videos from './gallery/videos';
import Chart from './admission/chart';
import Student_chart from './students/student_chart';
import Graduation from './students/graduation';

import Fee_chart from './fees/fee_chart';
import Staff_chart from './staff/staff_chart';
import Attendance_chart from './attendance/attendance_chart';
import Finance_chart from './finance/finance_chart';
import Payroll_chart from './payroll/payroll_chart';
import Library_chart from './library/library_chart';
import Transport_chart from './transport/transport_chart';



import Asset_registration from './assets/asset_registration';
import Bulk_registration from './assets/bulk_registration';
import View_assets from './assets/view_assets';
import Upload_assets from './assets/upload_assets';
import Edit_asset from './assets/edit_asset';

import Repair_history from './assets/repair_history';
import Allocation_history from './assets/allocation_history';
import Asset_finance from './assets/asset_finance';
import Asset_waranty from './assets/asset_waranty';
import Asset_chart from './assets/asset_chart';
import Conference_log from './staff/conference_log';
import Student_pickdrop from './attendance/student_pickdrop';
import Disable_student_result from './exam/disable_student_result';
import Automated_result_setup from './exam/automated_result_setup';
import Result_session from './exam/result_session';
import SubmitTicket from './communication/tickets/submitTicket';
import Class_session_cumulative from './exam/class_session_cumulative';
import Learners_wallet from './wallet/learners_wallet';
import Wallet from './wallet/wallet';
import Documents from './students/documents';
import ParentPortal from './settings/parentPortal';
import StudentPortal from './settings/studentPortal';
import StaffPortal from './settings/staffPortal';
import Delete_attendance from './attendance/delete_attendance';
import Biometric_device from './attendance/biometric_device';
import Operation from './attendance/operation';
import Staff_attendance_log from './attendance/staff_attendance_log';
import Student_attendance_log from './attendance/student_attendance_log';
import Agent from './admission/define_agent';
import Subject_mark_summary from './exam/subject_mark_summary';
import Startup from './setup/startup';
import Newsletter from './cms/newsletter';

import Sliders from './gallery/sliders';
import Create_new from './blog/create_new';
import EditPost from './blog/editPost';
import AllPosts from './blog/allPosts';
import Admission_flow from './admission/admission_flow';


const ProtectedView =()=>{


const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

      let closeWarning =   document.getElementById('btnLogoutsession')
              closeWarning.click();

           
const fd = new FormData();
var groupId = decrypt(Cookies.get('kagpcd'))


fd.append('code', staffCode)
  fd.append('groupId', groupId)
fd.append('jwt', Token)
fd.append('staffName', staffName)

    let url = ServerUrl+'/login_refresh_controller.php?tablename=tbl_refresh_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
			  
			var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
			var inOneMinutes = Date.now() + 418 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
        
			  localStorage.setItem('_expt', inSixtyMinutes)
				localStorage.setItem('_bexpt', inOneMinutes)

				Cookies.set('kaustnm', encrypt(response.data.staffName), {expires: inOneHours })
			Cookies.set('kauscd', encrypt(response.data.code), {expires: inOneHours })
			Cookies.set('kagpcd', encrypt(response.data.groupId), {expires: inOneHours })
			Cookies.set('kamtk', encrypt(response.data.jwt),  {expires: inOneHours })   
			Cookies.set('karfsh', encrypt(response.data.userToken),  {expires: inOneHours })
			

      Alerts('Info!', 'info', 'Successfuly reconnected')

       window.location.reload()

             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
    window.location.href='/logout'
}


const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(localStorage.getItem('_bexpt'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
}
  
     

useEffect(()=>{
  //fetchContent()
startWarning()
   
   setInterval(() => {
 var expiredTime = Number(localStorage.getItem('_expt'))
  if(expiredTime < Date.now()){
    handleLogout()
    //timeup logout user
  }
}, 5000)
}, [])


useEffect(()=>{
  if (localStorage.getItem("lcksr") !== null) {
    document.getElementById('openlockscreenModal').click()
 }
})


    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
      <Switch>


      <Route exact path="/dashboard"  render={(props) => <Dashboard menu="Dashboard" {...props } />} /> 


      <Route exact path="/setup/software_details" render={(props) => <Software_details  menu="Signature" {...props} />} /> 
      <Route exact path="/people/user/profile/:code" render={(props) => <UserProfile  menu="Signature" {...props} />} /> 


        <Route exact path="/setup/school_details" render={(props) => <BasicDetails menu="General"
        submenu="School Details"
        pageUrl="/general/settings" {...props} />} />  

       <Route exact path="/setup/session" render={(props) => <Session  menu="Session"
submenu="" {...props} /> }  />



        <Route exact path="/setup/school_classes" render={(props) => <Student_class  menu="General"
    submenu="Class & Section" {...props} />} /> 
         <Route exact path="/setup/subject" render={(props) => <Subject menu="General" submenu='Subject' {...props} />} /> 
      
{/* students router start here */}
     <Route exact path="/students/house_summary" render={(props) => <HouseSummary  menu="Students" submenu='Report' {...props} />} />  
       <Route exact path="/students/club_summary" render={(props) => <ClubSummary menu="Students" submenu='Report' {...props} />} />  
       <Route exact path="/students/family_summary" render={(props) => <FamilySummary  menu="Students" submenu='Report' {...props} />} />  
       <Route exact path="/students/class_list" render={(props) => <ClassList  menu="Students" submenu='Report' {...props} />} />  
       <Route exact path="/students/class_summary" render={(props) => <ClassReportSummary  menu="Students" submenu='Report' {...props} />} />  
       <Route exact path="/students/student_addresses" render={(props) => <HouseAddress  menu="Students" submenu='Report' {...props} />} />  
     <Route exact path="/students/login_report" render={(props) => <Login_report  menu="Students"
        submenu="Report" {...props} />} />  
        <Route exact path="/students/change_password" render={(props) => <Change_password   menu="Students"
        submenu="Change Password" {...props} />} />  
<Route exact path="/student/profile/:code" render={(props) => <StudentProfile  menu="Students" submenu='View All Students' {...props} />} />  
        <Route exact path="/students/registration" render={(props) => <StudentAdmission  menu="Students" submenu='Registration' {...props} />} /> 
      <Route exact path="/students/view_students" render={(props) => <ViewStudents menu="Students" submenu='View All Students' {...props} />} /> 
       <Route exact path="/students/settings" render={(props) => <MasterEntry  menu="Students" submenu='Settings' {...props} />} /> 
         <Route exact path="/students/import_students" render={(props) => <UploadStudents  menu="Students" submenu='Registration' {...props} />} /> 

        <Route exact path="/students/bulk_registration" render={(props) => <BulkStudents  menu="Students" submenu='Registration' {...props} />} /> 

        <Route exact path="/students/promotion" render={(props) => <Promotion  menu="Students" submenu='Promote Students' {...props} />} /> 
         <Route exact path="/students/transfer_class" render={(props) => <Transfer menu="Students" submenu='Transfer Student' {...props} />} /> 
       <Route exact path="/students/define_family" render={(props) => <Family  menu="Students" submenu='Setup Family' {...props} />} /> 
       <Route exact path="/students/review" render={(props) => <StudentReview  menu="Students" submenu='Report' {...props} />} /> 
      <Route exact path="/students/graduate_student" render={(props) => <Graduation  menu="Students" submenu='Graduate Students' {...props} />} /> 

       <Route exact path="/students/chart" render={(props) => <Student_chart  menu='Students' submenu='Student Chart' {...props} />} /> 

       <Route exact path="/students/documents" render={(props) => <Documents  menu='Students' submenu='Student Document' {...props} />} /> 
{/* students router end here */}



{/* Academics router start here */}
        <Route exact path="/exam/standards" render={(props) => <ExamStandard  menu="Academics" submenu='Standards Setting'{...props} />} /> 
         <Route exact path="/exam/settings" render={(props) => <ExamSettings  menu="Academics" submenu='Result Settings'{...props} />} /> 
         <Route exact path="/exam/result_setup" render={(props) => <ResultSetup  menu="Academics" submenu='Create Result' {...props} />} /> 
         <Route exact path="/exam/result_entry" render={(props) => <ResultEntry  menu="Academics" submenu='Terminal Result Entry' {...props} />} /> 
         <Route exact path="/exam/mock_result_entry" render={(props) => <MockResultEntry  menu="Academics" submenu='Mock Result Entry' {...props} />} /> 
       <Route exact path="/exam/class_subject" render={(props) => <ClassSubject menu="Academics" submenu='Assign Subject To Class' {...props} />} /> 
       
       <Route exact path="/exam/class_session_cummulative"  render={(props) => <Class_session_cumulative menu="Dashboard" {...props } />} /> 
         <Route exact path="/exam/result_attendance" render={(props) => <Result_attendance  menu="Academics" submenu='Result Attendance' {...props} />} /> 
        <Route exact path="/exam/subject_teacher" render={(props) => <SubjectTeacher  menu="Academics" submenu='Assign Staff To Exam Subject' {...props} />} /> 
       
       <Route exact path="/exam/chart" render={(props) => <Exam_chart  menu='Academics' submenu='Exam Chart' {...props} />} /> 

      <Route exact path="/exam/assesment_entry" render={(props) => <AssessmentEntry  menu="Academics" submenu='Standards Entry' {...props} />} />   
       <Route exact path="/exam/subject_marks" render={(props) => <SubjectMark  menu="Academics" submenu='Report' {...props} />} /> 
     <Route exact path="/exam/exam_schedule" render={(props) => <ExamSchedule  menu="Academics" submenu='Report'  {...props} />} /> 


    {/*  <Route exact path="/exam/section_marks" render={(props) => <SectionMark  menu="Academics" submenu='Report' {...props} />} /> */}
        <Route exact path="/exam/class_marks" render={(props) => <ClassMark  menu="Academics" submenu='Report' {...props} />} /> 
         <Route exact path="/exam/view_result" render={(props) => <ViewResult  menu="Academics" submenu='Report' {...props} />} />       

      <Route exact path="/exam/mid_term_result" render={(props) => <ViewMidResult  menu="Academics" submenu='Report' {...props} />} />        
     <Route exact path="/exam/result_comment" render={(props) => <ResultComment  menu="Academics" submenu='Comment Entry' {...props} />} />       
       <Route exact path="/exam/student_transcript" render={(props) => <Transcript  menu="Academics" submenu='Report' {...props} />} />  
     
       <Route exact path="/exam/result_session" render={(props) => <Result_session  menu="Academics" submenu='Report' {...props} />} /> 

       <Route exact path="/exam/update_class_result"  render={(props) => <ResultSetupUpdate menu="Update Result" {...props } />} />  
       <Route exact path="/exam/disable_student_result"  render={(props) => <Disable_student_result menu="Disable Result" {...props } />} /> 

       <Route exact path="/exam/automated_result_setup"  render={(props) => <Automated_result_setup menu="Automated Result" {...props } />} /> 


       <Route exact path="/exam/subject_mark_summary"  render={(props) => <Subject_mark_summary menu="Subject Summary" {...props } />} /> 

       

{/* Academic router end here */}

{/* Fees Management router start here */}
      <Route exact path="/fees/fee_type" render={(props) => <Fee_type  menu="Fees Management" submenu='Fees Type' {...props} />} /> 
        <Route exact path="/fees/fee_charge" render={(props) => <FeeCharge  menu="Fees Management" submenu='Fees Charge' {...props} />} /> 
       <Route exact path="/fees/class_fees" render={(props) => <Class_fee  menu="Fees Management" submenu='Class Fees Structure' {...props} />} /> 
       <Route exact path="/fees/student_fees" render={(props) => <StudentFees  menu="Fees Management" submenu='Student Fees Schedule' {...props} />} /> 
      <Route exact path="/fees/update_student_fees" render={(props) => <UpdateStudentFees  menu="Fees Management" submenu='Update Student Fees' {...props} />} /> 
      <Route exact path="/fees/fees_payment" render={(props) => <FeesPayment  menu="Fees Management" submenu='Fees Payment' {...props} />} /> 
      <Route exact path="/fees/fees_reminder" render={(props) => <Fee_reminder menu="Fees Management" submenu='Fees Reminder' {...props} />} /> 
       <Route exact path="/fees/print_voucher" render={(props) => <PrintVoucher  menu="Fees Management" submenu='Report' {...props} />} /> 
       <Route exact path="/fees/date_wise_report" render={(props) => <DateWiseReport menu="Fees Management" submenu='Report' {...props} />} /> 
      <Route exact path="/fees/print_class_schedule" render={(props) => <ClassFeesSChedule  menu="Fees Management" submenu='Report' {...props} />} /> 
     <Route exact path="/fees/fees_register" render={(props) => <FeeRegister  menu="Fees Management" submenu='Report' {...props} />} /> 
      <Route exact path="/fees/payment_history" render={(props) => <PaymentHistory  menu="Fees Management" submenu='Report' {...props} />} /> 
      <Route exact path="/fees/online_payment_history" render={(props) => <Online_payment_history  menu="Fees Management" submenu='Report' {...props} />} /> 
       <Route exact path="/fees/family_fees" render={(props) => <FamilyFees  menu="Fees Management" submenu='Report' {...props} />} /> 
      <Route exact path="/fees/chart" render={(props) => <Fee_chart  menu='Fees Management' submenu='Fees Chart' {...props} />} /> 
{/* Fees Management router end here */}



{/* Staff router start here */}
      <Route exact path="/staff/settings" render={(props) => <StaffSettings  menu="Manage Staff" submenu='Manage' {...props} />} /> 
       <Route exact path="/staff/staff_registration" render={(props) => <StaffRegistration  menu="Staff" submenu='Registration' {...props} />} /> 
      <Route exact path="/staff/view_staff" render={(props) => <ViewStaff  menu="View All Staff" submenu='View All Staff' {...props} />} /> 
      <Route exact path="/staff/chart" render={(props) => <Staff_chart  menu='Staff' submenu='Staff Chart' {...props} />} /> 
      <Route exact path="/staff/profile/:code" render={(props) => <StaffProfile  menu="Staff" submenu='Report' {...props} />} /> 

       <Route exact path="/staff/bulk_registration" render={(props) => <BulkStaff menu="Staff" submenu='Registration' {...props} />} /> 

       <Route exact path="/signature" render={(props) => <MySignature menu="Signature" {...props } />  } />  

       <Route exact path="/staff/upload_staff" render={(props) => <UploadStaff  menu="Staff" submenu='Registration' {...props} />} /> 
        <Route exact path="/staff/assign_class_teacher" render={(props) => <ClassTeacher  menu="Staff" submenu='Assign Class Teacher' {...props} />} /> 
       <Route exact path="/staff/review" render={(props) => <StaffReview   menu="Staff" submenu='Report'{...props} />} /> 

       <Route exact path="/staff/login_report" render={(props) => <LoginReportStaff   menu="Staff"
        submenu="Report" {...props} />} />  

       <Route exact path="/staff/change_password" render={(props) => <Changestaffpassword  menu="Staff"
        submenu="Change Password" {...props} />} />  

     

<Route exact path="/staff/roles" render={(props) => <UserRoles  menu="Staff"
        submenu="Staff Roles" {...props} />} /> 
  <Route exact path="/staff/groups" render={(props) => <UserGroup  menu="Staff"
        submenu="Staff Group" {...props} />} /> 
     <Route exact path="/staff/create_conference" render={(props) => <Staff_conference  menu="Staff" submenu='Student Conference' {...props} />} />  

      <Route exact path="/staff/conference_log" render={(props) => <Conference_log menu="Staff" submenu='Report' {...props} />} />  
{/* Staff router end here */}


{/* Attendance router start here */}

     <Route exact path="/attendance/staff_attendance" render={(props) => <StaffAttendance  menu="Attendance" submenu='Staff Attendance' {...props} />} /> 
     <Route exact path="/attendance/chart" render={(props) => <Attendance_chart   menu='Attendance' submenu='Attendance Chart' {...props} />} /> 
      <Route exact path="/attendance/upload_staff_attendance" render={(props) => <UploadStaffAttendance  menu="Attendance" submenu='Import Staff Attendance'{...props} />} /> 

        <Route exact path="/attendance/display_staff_attendance" render={(props) => <DisplayStaffAttendance  menu="Attendance" submenu='Display Staff Attendance' {...props} />} /> 




  <Route exact path="/attendance/student_attendance" render={(props) => <StudentAttendance  menu="Attendance" submenu='Student Attendance'{...props} />} /> 

        <Route exact path="/attendance/display_student_attendance" render={(props) => <DisplayStudentAttendance  menu="Attendance" submenu='Display Student Attendance' {...props} />} /> 

       <Route exact path="/attendance/student_arrival_departure" render={(props) => <StudentLateArrival  menu="Attendance" submenu='Student Late Arrival & Early Departure' {...props} />} /> 

      <Route exact path="/attendance/student_attendance_summary" render={(props) => <StudentAttendanceSummary  menu="Attendance" submenu='Report' {...props} />} /> 

      <Route exact path="/attendance/staff_attendance_summary" render={(props) => <StaffAttendanceSummary menu="Attendance" submenu='Report'{...props} />} /> 

     <Route exact path="/attendance/staff_working_hours" render={(props) => <StaffWorkinHours  menu="Attendance" submenu='Report' {...props} />} /> 
        
     <Route exact path="/attendance/delete" render={(props) => <Delete_attendance  menu="Attendance" submenu='Report' {...props} />} /> 


     <Route exact path="/attendance/student_pick_drop" render={(props) => <Student_pickdrop  menu="Attendance" submenu='Student Pick & Drop' {...props} />} /> 
    
     <Route exact path="/attendance/biometric/device" render={(props) => <Biometric_device  menu="Attendance" submenu='Biometric' {...props} />} /> 
     <Route exact path="/attendance/biometric/operations" render={(props) => <Operation menu="Attendance" submenu='Operations' {...props} />} /> 

     <Route exact path="/attendance/attendance/staff_log" render={(props) => <Staff_attendance_log menu="Attendance" submenu='Operations' {...props} />} /> 

     <Route exact path="/attendance/attendance/student_log" render={(props) => <Student_attendance_log menu="Attendance" submenu='Report' {...props} />} /> 

{/* Staff router end here */}


{/* Finance router start here */}
        <Route exact path="/finance/account_type" render={(props) => <AccountType  menu="Finance" submenu='Account Type' {...props} />} /> 
         <Route exact path="/finance/create_account" render={(props) => <CreateAccount  menu="Finance" submenu='Create Account' {...props} />} /> 
      <Route exact path="/finance/account_head" render={(props) => <AccountHead  menu="Finance" submenu='Income & Expenses Type' {...props} />} /> 
    <Route exact path="/finance/record_income" render={(props) => <RecordIncome   menu="Finance" submenu='Income' {...props} />} /> 
       <Route exact path="/finance/chart" render={(props) => <Finance_chart   menu='Finance' submenu='Finance Chart' {...props} />} /> 
       <Route exact path="/finance/bulk_income" render={(props) => <BulkIncome  menu="Finance" submenu='Income' {...props} />} /> 

      <Route exact path="/finance/bulk_expenses" render={(props) => <Bulk_expenses menu="Finance" submenu='Expenses'{...props} />} /> 
        <Route exact path="/finance/record_expenses" render={(props) => <RecordExpenses  menu="Finance" submenu='Expenses' {...props} />} />         
       <Route exact path="/finance/upload_expenses" render={(props) => <UploadExpenses  menu="Finance" submenu='Expenses' {...props} />} /> 
        <Route exact path="/finance/upload_income" render={(props) => <UploadIncome  menu="Finance" submenu='Income' {...props} />} /> 
         <Route exact path="/finance/account_statement" render={(props) => <AccountStatement  menu="Finance" submenu='Report' {...props} />} /> 
       <Route exact path="/finance/daily_register" render={(props) => <DailyRegister  mmenu="Finance" submenu='Report' {...props} />} /> 


{/* Finance router end here */}

{/* Payroll router start here */}

      <Route exact path="/payroll/settings" render={(props) => <PayrollSettings  menu="Payroll" submenu='Settings' {...props} />} /> 
       <Route exact path="/payroll/salary_structure" render={(props) => <SalaryStructure  menu="Payroll" submenu='Salary Structure' {...props} />} />         
       <Route exact path="/payroll/assign_salary_structure" render={(props) => <AssignSalaryStructure  menu="Payroll" submenu='Assign Salary Structure'{...props} />} /> 
     <Route exact path="/payroll/salary_payment" render={(props) => <SalaryPayment  menu="Payroll" submenu='Salary Payment' {...props} />} />  
     <Route exact path="/payroll/apply_leave" render={(props) => <ApplyLeave  menu="Payroll" submenu='Approve Leave' {...props} />} />         
       <Route exact path="/payroll/approve_leave" render={(props) => <ApproveLeave   menu="Payroll" submenu='Approve Leave' {...props} />} />              
     <Route exact path="/payroll/payslip" render={(props) => <Payslip  menu="Payroll" submenu='Report' {...props} />} />  
    <Route exact path="/payroll/staff_salary" render={(props) => <StaffSalary menu="Payroll" submenu='Report' {...props} />} />  
       <Route exact path="/payroll/chart" render={(props) => <Payroll_chart  menu='Payroll' submenu='Payroll Chart' {...props} />} /> 


{/* Payrollrouter end here */}


{/* Library router start here */}

      <Route exact path="/library/settings" render={(props) => <LibrarySettings menu="Library" submenu='Settings'{...props} />} /> 
      <Route exact path="/library/manage_books" render={(props) => <ManageBooks  menu="Library" submenu='Manage Books' {...props} />} /> 
     <Route exact path="/library/book_list" render={(props) => <BookList menu="Library" submenu='Book List' {...props} />} /> 
         <Route exact path="/library/books" render={(props) => <Books  menu="Library" submenu='View All Books' {...props} />} /> 

        <Route exact path="/library/bulk_books" render={(props) => <Bulk_register  menu="Library" submenu='Manage Books' {...props} />} /> 
       <Route exact path="/library/chart" render={(props) => <Library_chart   menu='Library' submenu='Library Chart' {...props} />} /> 

        <Route exact path="/library/eLibrary" render={(props) => <ELibrary  menu="Library" submenu='E-Library' {...props} />} /> 
      
       <Route exact path="/library/upload_books" render={(props) => <UploadBooks  menu="Library" submenu='Manage Books' {...props} />} /> 

       <Route exact path="/library/book_qrcode" render={(props) => <BookBarcode  menu="Library" submenu='Report' {...props} />} /> 

        <Route exact path="/library/issues_return/student" render={(props) => <IssuesReturn menu="Library" submenu='Issue & Return' {...props} />} /> 

        <Route exact path="/library/book_issue_report/student" render={(props) => <BookIssueReport  menu="Library" submenu='Report' {...props} />} /> 

         
        <Route exact path="/library/book_issue_report/staff" render={(props) => <BookIssueReportStaff  menu="Library" submenu='Report'{...props} />} /> 
      
        <Route exact path="/library/issues_return/staff" render={(props) => <IssuesReturnStaff  menu="Library" submenu='Issue & Return' {...props} />} /> 


{/* Library router end here */}


{/* Timetable router start here */}


     <Route exact path="/timetable/activity" render={(props) => <TimetableSettings menu="Timetable" submenu='Activity' {...props} />} /> 
       <Route exact path="/timetable/setup" render={(props) => <Setup  menu="Timetable" submenu='Automatic Timetable Setup' {...props} />} /> 

        <Route exact path="/timetable/setup_class_timetable" render={(props) => <TimetableByDay menu="Timetable" submenu='Timetable Day Wise'   {...props} />} />   

       <Route exact path="/timetable/class_lesson_plan" render={(props) => <TimetableByDate  menu="Timetable" submenu='Class Lesson Plan' {...props} />} />  
       <Route exact path="/timetable/define_holidays" render={(props) => <DefineHolidays   menu="Timetable" submenu='Setup Holidays' {...props} />} />        
       <Route exact path="/timetable/holidays_list" render={(props) => <HolidaysList menu="Timetable" submenu='Report' {...props} />} />  
       <Route exact path="/timetable/view_timetable" render={(props) => <ViewTimetable  menu="Timetable" submenu='Report' {...props} />} />  
       <Route exact path="/timetable/staff_schedule" render={(props) => <StaffSchedule  menu="Timetable" submenu='Staff Schedule' {...props} />} />  
                  
      <Route exact path="/timetable/staff_subject_allocation" render={(props) => <StaffSubjectAllocation   menu="Timetable" submenu='Setup Holidays' {...props} />} /> 

     <Route exact path="/timetable/assign_subject_teacher" render={(props) => <AssignSubjectTeacher  menu="Signature" {...props} />} />    

      <Route exact path="/timetable/view_subject_plan" render={(props) => <ViewSubjectPlan  menu="Timetable" submenu='Class Lesson Plan' {...props} />} /> 


      {/* Timetable router end here */}



{/* Communication  router start here */}

<Route exact path="/communication/emailapi" render={(props) => <EmailApi  menu="Communication"
        submenu="Email API Setting" {...props} />} /> 
 <Route exact path="/communication/smsapi" render={(props) => <SmsApi menu="Communication"
        submenu="SMS API Setting" {...props} />} /> 
 <Route exact path="/communication/noticeboard" render={(props) => <Noticeboard  menu="Communication"
        submenu="Noticeboard" {...props} />} /> 
<Route exact path="/communication/template" render={(props) => <Template  menu="Communication"
        submenu="Message Template" {...props} />} /> 

<Route exact path="/cms/newsletter"  render={(props) => <Newsletter menu="Newsletter" {...props } />} /> 
<Route exact path="/cms/sliders"  render={(props) => <Sliders menu="Sliders" {...props } />} /> 
<Route exact path="/cms/news/edit/:code"  render={(props) => <EditPost menu="Create News" {...props } />} /> 

<Route exact path="/cms/news"  render={(props) => <AllPosts menu="News" {...props } />} /> 
<Route exact path="/cms/news/add"  render={(props) => <Create_new menu="Create News" {...props } />} /> 

<Route exact path="/communication/template/edit/:code" render={(props) => <EditTemplate  menu="Communication"
        submenu="Message Template" {...props} />} /> 

 <Route exact path="/communication/tickets" render={(props) => <Ticket  menu="Communication"
        submenu="Tickets" {...props} />} /> 

 <Route exact path="/communication/tickets/create" render={(props) => <SubmitTicket  menu="Communication"
        submenu="Tickets" {...props} />} /> 


<Route exact path="/communication/ticket/reply/:code" render={(props) => <ReplyTicket  
			menu="Communication"
			submenu="Tickets" {...props} />} /> 
<Route exact path="/communication/send_message/parent" render={(props) => <SendMessage   menu="Communication"
        submenu="Send Bulk Message" {...props} />} />       


       <Route exact path="/communication/message_report" render={(props) => <MessageReport  menu="Communication"
        submenu="Report" {...props} />} />        

       <Route exact path="/communication/send_login_details/student" render={(props) => <SendLoginDetails  menu="Signature" {...props} />} />  

  <Route exact path="/communication/send_message/staff" render={(props) => <SendStaffMessage  menu="Communication"
        submenu="Send Bulk Message" {...props} />} /> 
       
        <Route exact path="/communication/send_login_details/staff" render={(props) => <SendStaffLoginDetails  menu="Signature" {...props} />} />  

   <Route exact path="/communication/birthdays" render={(props) => <Birthdays  menu="Communication"
        submenu="Report" {...props} />} /> 
     
        
       {/* Communication router end here */}


       <Route exact path="/general/payment-gateway" render={(props) => <PaymentGateway   menu="General"
        submenu="Payment Gateway" {...props} />} /> 
     <Route exact path="/general/errorlog" render={(props) => <ErrorLog  menu="General"
        submenu="Error Log" {...props} />} /> 
   <Route exact path="/general/sitemap" render={(props) => <Sitemap  menu="General"
        submenu="Sitemap" {...props} />} /> 

      <Route exact path="/general/navigation" render={(props) => <Navigation 
          menu="General"
          submenu="Navigations"
          pageUrl="/general/navigation" {...props} />} /> 

<Route exact path="/general/navigation/parent" render={(props) => <ParentPortal 
          menu="General"
          submenu="Navigations"
          pageUrl="/general/navigation/parent" {...props} />} /> 

<Route exact path="/general/navigation/student" render={(props) => <StudentPortal 
          menu="General"
          submenu="Navigations"
          pageUrl="/general/navigation/student" {...props} />} /> 



<Route exact path="/general/navigation/staff" render={(props) => <StaffPortal 
          menu="General"
          submenu="Navigations"
          pageUrl="/general/navigation/staff" {...props} />} />


     <Route exact path="/general/database/backup" render={(props) => <Backup   menu="General"
        submenu="Database" {...props} />} /> 
    <Route exact path="/general/database/restore" render={(props) => <Restore  menu="General"
        submenu="Database" {...props} />} /> 

 <Route exact path="/people/users" render={(props) => <Users  menu="People"
        submenu="Users" {...props} />} /> 
 <Route exact path="/people/user/create" render={(props) => <AddUsers   menu="People"
        submenu="Users" {...props} />} /> 
<Route exact path="/people/user/edit/:code" render={(props) => <EditUsers  menu="People"
        submenu="Users" {...props} />} /> 


 <Route exact path="/people/view_users" render={(props) => <ViewUsers  menu="People"
        submenu="Users" {...props} />} /> 
 <Route exact path="/people/login_report" render={(props) => <LoginReport menu="People"
        submenu="Reports" {...props} />} /> 
 <Route exact path="/people/usertype_permission" render={(props) => <UsertypePermission  menu="Signature" {...props} />} /> 
    
    
    
    

 <Route exact path="/admission/applicationType" render={(props) => <AdmissionSettings  menu="Admission Process" submenu='Application Type' {...props} />} /> 
 <Route exact path="/admission/startAdmission" render={(props) => <StartAdmission  menu="Admission Process" submenu='Start Admission' {...props} />} /> 
 <Route exact path="/admission/admission_request" render={(props) => <AdmissionRequest  menu="Admission Process"
        submenu="Admission Request" {...props} />} /> 

<Route exact path="/admission/chart" render={(props) => <Chart menu='Admission Process' submenu='Admission Chart' {...props} />} /> 

<Route exact path="/admission/agents" render={(props) => <Agent menu='Admission Agents' submenu='Admission Agents' {...props} />} /> 

<Route exact path="/admission/flow" render={(props) => <Admission_flow menu='Admission Flow' submenu='Admission Agents' {...props} />} /> 



<Route exact path="/hostel/view_all" render={(props) => <Hostel  menu="Hostel" submenu='View Hostel' {...props} />} /> 
<Route exact path="/hostel/allocation" render={(props) => <Hostel_allocation menu="Hostel" submenu='Hostel Allocation' {...props} />} /> 
<Route exact path="/hostel/application" render={(props) => <Hostel_application  menu="Hostel" submenu='Hostel Request' {...props} />} /> 
<Route exact path="/hostel/transfer" render={(props) => <Transfer_student  menu="Hostel" submenu='Transfer Student' {...props} />} /> 
<Route exact path="/hostel/report/room_occupant" render={(props) => <Room_occupant menu="Hostel" submenu='Reports' {...props} />} /> 
<Route exact path="/hostel/visitors" render={(props) => <Hostel_visitors   menu="Hostel" submenu='Hostel Visitors' {...props} />} /> 
<Route exact path="/hostel/report/room_payment" render={(props) => <Room_payment  menu="Hostel" submenu='Reports' {...props} />} /> 
<Route exact path="/hostel/report/room_availability" render={(props) => <Room_availability menu="Hostel" submenu='Reports' {...props} />} /> 
<Route exact path="/hostel/chart" render={(props) => <Hostel_chart menu="Hostel" submenu='Hostel Chart' {...props} />} /> 



<Route exact path="/transport/create_route" render={(props) => <Create_route  menu="Transport" submenu='Create Route' {...props} />} /> 
<Route exact path="/transport/vehicle" render={(props) => <Vehicle  menu="Transport" submenu='Vehicle' {...props} />} /> 
<Route exact path="/transport/create_destination" render={(props) => <Create_destination  menu="Transport" submenu="Create Destination" {...props} />} /> 
<Route exact path="/transport/assign_student" render={(props) => <Assign_student  menu="Transport" submenu='Assigning Student To Route' {...props} />} /> 
<Route exact path="/transport/chart" render={(props) => <Transport_chart  menu='Transport' submenu='Transport Chart' {...props} />} /> 




  <Route exact path="/cms/gallery" render={(props) => <Gallery  menu="CMS" submenu="Gallery" {...props} />} /> 
 <Route exact path="/cms/videos" render={(props) => <Videos menu="CMS" submenu="Videos" {...props} />} /> 
<Route exact path="/cms/gallery/add_image/:imageId" render={(props) => <GalleryList  menu="CMS" submenu="Gallery" {...props} />} /> 



 <Route exact path="/assets/update/:code" render={(props) => <Edit_asset   menu="Assets Management" submenu='Registration' {...props} />} /> 
<Route exact path="/assets/bulk_registration" render={(props) => <Bulk_registration  menu="Assets Management" submenu='Registration' {...props} />} /> 
<Route exact path="/assets/registration" render={(props) => <Asset_registration  menu="Assets Management" submenu='Registration' {...props} />} /> 
<Route exact path="/assets/upload_assets" render={(props) => <Upload_assets  menu="Assets Management" submenu='Registration' {...props} />} /> 
<Route exact path="/assets/view_all" render={(props) => <View_assets   menu="Assets Management" submenu='View All Asset' {...props} />} /> 
<Route exact path="/assets/report/repairs" render={(props) => <Repair_history  menu="Assets Management" submenu='Reports' {...props} />} /> 
  <Route exact path="/assets/report/allocation_history/:code" render={(props) => <Allocation_history menu="Assets Management"  submenu='Reports' {...props} />} /> 
 <Route exact path="/assets/report/finance" render={(props) => <Asset_finance menu="Assets Management" submenu='Reports'{...props} />} /> 
 <Route exact path="/assets/report/expiry_warranty" render={(props) => <Asset_waranty  menu="Assets Management" submenu='Reports' {...props} />} /> 
 <Route exact path="/assets/chart" render={(props) => <Asset_chart 
            menu="Assets Management" submenu='Asset Chart' {...props} />} /> 


<Route exact path="/wallet" render={(props) => <Wallet menu="Wallet" submenu='Reports' {...props} />} /> 
<Route exact path="/wallet/transactions/:code" render={(props) => <Learners_wallet  menu="Wallet" submenu='Reports' {...props} />} /> 


<Route exact path="/startup" render={(props) => <Startup menu="Startup" submenu='Reports' {...props} />} /> 


        <Route exact render={(props) => <Error404  menu="Signature" {...props} />} /> 
      </Switch>
      </>
    );
  
}
export default ProtectedView;
