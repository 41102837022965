import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, FormatNumber, Currency,  ImagesUrl, config} from '../component/include'
import Select from 'react-select'
import {getMonthByDate, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

const Payslip =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [staffDetails, setStaffDetails] = useState([]); 
    const [staff, setStaff]= useState([]) 
    const [salary, setSalary]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);   
    const [schoolInfo, setSchoolInfo]=useState([])
    const [payslip, setPayslip] = useState({
        staff:[],
        payMonth:''
    })
    
   
    const fetchSchoolInfo =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }

    const handleSelect= (option, action)=>{
        setPayslip({...payslip, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        setSalary('')
        setStaffDetails(staff.filter(item=>item.code===option.value))
    }
    
    const handleChange=(event)=>{
        setPayslip({...payslip, [event.target.name]:event.target.value});
        setErrors({...errors, [event.target.name]:''})
        setSalary('')
    }
 
    const getTotalEarn =(nsalary)=>{
        
         var amount =0
       
        JSON.parse(nsalary).map((sl)=>
       sl.salaryHead==='EARNING'?amount += Number(sl.paid):''
       )
       return Number(amount) 
    }

    const getTotalDeduction=(nsalary)=>{
         var amount =0
        JSON.parse(nsalary).map((sl)=>
        sl.salaryHead==='DEDUCTION'?amount += Number(sl.paid):''
        )
        return Number(amount) 
    }

    const  fetchStaff =()=>{  
        const fd = new FormData();
        fd.append('jwt', Token);
   let sql = "Select code, staffName, position, mobile, email, altMobile, presentAddress, fileNo from tbl_staff"
       let url = ServerUrl+'/fetchBySql_controller'
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>setStaff(result.data)) 
    }   

    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(payslip));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};  
           
        if(values.staff.length===0){
            errors.staff ='Please select staff';
        } 
        if(!values.payMonth){
            errors.payMonth ='Please select pay month';
        } 
       
        return errors;
        }


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    const  submit=()=>{  
              
    setNotice({...notice, 
        isLoading: true})   

        const fd = new FormData();
        fd.append('jwt', Token);

        let sql = "Select s.ID, s.code, s.paymentMonth, s.salaryStructure, s.paymentDate, s.totalAmount, p.paymentType, s.remarks from tbl_salary_payment s, tbl_payment_type p  where  s.paymentType = p.code and s.staff ='"+payslip.staff.value+"' and s.paymentMonth = '"+payslip.payMonth+"'"
     
            let url = ServerUrl+'/fetchBySql_controller'
          
            fd.append("sql", sql)
            
            axios.post(url, fd, config).then(result =>{
                if(result.data.length!==0){
                    setSalary(result.data)
                }else{
                    Alerts('Info!', 'default', 'No record found')
                }
                
                setNotice({...notice, 
                    isLoading: false
                })
            }) 
     }    

useEffect(()=>{
    fetchStaff()   
    fetchSchoolInfo()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Print Payslip">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Print Payslip</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Print Payslip</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
   <div className="form-group">
<label >Select Staff</label><a href="/payroll/salary_payment" className="pull-right">Define Payment</a>
<Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>` ${option.staffName} - ${option.label}`}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={payslip.staff}  />  <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
   </div>
   
</section>

<section className="col-md-6">
   
<div className="form-group">
<label >Month Year</label>
<input type="month" className={errors.payMonth ? 'form-control form-control-danger' : 'form-control'} name="payMonth" id="payMonth"  onChange={handleChange} value={payslip.payMonth} />
<span style={{color:'red'}}>{errors.payMonth && errors.payMonth}</span>
   </div>
</section>


				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-primary "><i className="fa fa-file"></i> Get Payslip</button>
								</footer>		</section>		
							</div></div> </div> 
							
</div>

</div>      


  </div> </div> 
							
                            </div>
                            
                            </div>


{salary&&salary.length!==0?

<form method='POST' action={ServerUrl+'/printout/staff_pay_slip.php?token='+userToken} target='_blank'>
    <div className="card">
        <div className="row invoice-contact">
            <div className="col-md-8">
                <div className="invoice-box row">
                    <div className="col-sm-12">
                       
                       {schoolInfo&&schoolInfo.map((st, id)=>
                        <table  key={id} className="table table-responsive invoice-table table-borderless">
                            <tbody>
                                <tr>
                                    <td>
                                        
                    <img id="viewSchoolLogo2" className="user-img img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/about/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  style={{display:'block', height:'100px',width:'100px'}} title="School Logo" alt=''  />   </td>
                                </tr>
                                <tr>
                                    <td><b>{st.schoolName}</b></td>
                                </tr>
                                <tr>
                                    <td>{st.address}</td>
                                </tr>
                                <tr>
                                    <td>{st.email}</td>
                                </tr>
                                <tr>
                                    <td>{st.mobile} &nbsp; {st.altMobile}</td>
                                </tr>
                                 <tr>
                <td><a href="#!" target="_blank">{st.website}</a>
                </td>
            </tr> 
                            </tbody>
                        </table>)}
                    </div>
                </div>
            </div>
            <div className="col-md-4">
            </div>
        </div>
        <div className="row">
        <div className="col-md-6">
        <h5 className="pull-right"><u><b>Salary Payslip </b></u></h5>
        </div></div>
        <div className="card-block">
            <div className="row invoive-info">
            {staffDetails&&staffDetails.map((c, id)=>
                <div key={id} className="col-md-4 col-xs-12 invoice-client-info">
                    <h6>Employee Information :</h6>
                    <h6 className="m-0">{c.staffName}</h6>
                    <p className="m-0 m-t-10">{c.presentAddress}</p>
                    <p className="m-0">{c.mobile}&nbsp; </p>
                    <p>{c.email}</p>
                </div>
        )}
        
                <div className="col-md-4 col-sm-6">
                    <h6>Salary Information :</h6>
                    {salary&&salary.map((sr, idx)=>
                    <table  key={idx} className="table table-responsive invoice-table invoice-order table-borderless">
                        <tbody>
                            <tr>
                                <th>Payment Month :</th>
                                <td>{getMonthByDate(sr.paymentMonth)}</td>
                            </tr>
                            <tr>
                                <th>Date Pay:</th>
                    <td>{longDate(sr.paymentDate)}</td>
                            </tr>
                            <tr>
                                <th>Invoice Number :</th>
                                <td>
                                   # {sr.ID}
                                </td>
                            </tr>
                        </tbody>
                    </table>)}
                </div>
                {salary&&salary.map((sr, idx)=>
                <div key={idx} className="col-md-4 col-sm-6">
                    <h6 className="m-b-20">Payment Details</h6>
                    <h6 className="text-uppercase text-primary">Total Paid :
                        <span>{Currency+FormatNumber(sr.totalAmount)}</span>
                        
                    </h6>
                    <h6 className="text-uppercase text-primary">Payment Mode: <span>{sr.paymentType}</span></h6>
                </div>)}
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="table-responsive">
                        <table className="table  invoice-detail-table">
                            <thead>
                                <tr className="thead-default">
                                    <th>Description</th>
                                    <th>Earnings</th>
                                    <th>Deductions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {salary&&salary.map((sr)=>JSON.parse(sr.salaryStructure).map((str, idx)=>Number(str.paid)!==0?

                                <tr key={idx}>
                                    <td>
                                        <h6>{str.title} (<b>{Currency+FormatNumber(str.amount)}</b>)</h6>
                                    </td>
                                    <td>{str.salaryHead==='EARNING'?Currency+FormatNumber(str.paid):'-'}</td>
                                    <td>{str.salaryHead==='DEDUCTION'?Currency+FormatNumber(str.paid):'-'}</td>
                                    
                                </tr>:[]
))}
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
{salary&&salary.map((sr, id)=>
            <div key={id} className="row">
                <div  className="col-sm-12">
                    <table className="table table-responsive invoice-table invoice-total">
                        <tbody>
                            <tr>
                                <th>Total Earnings :</th>
                                <td>{Currency+FormatNumber(getTotalEarn(sr.salaryStructure))}
                                <input type='hidden' name='earning' value ={getTotalEarn(sr.salaryStructure)} /></td>
                            </tr>
                            <tr>
                                <th>Total Deductions :</th>
                                <td>{Currency+FormatNumber(getTotalDeduction(sr.salaryStructure))}
                                <input type='hidden' name='deduction' value ={getTotalDeduction(sr.salaryStructure)} />
                                </td>
                            </tr>
                            <tr className="text-info">
                                <td>
                                    <hr/>
                                    <h5 className="text-primary">NET Salary :</h5>
                                </td>
                                <td>
                                    <hr/>
                                    <h5 className="text-primary">{Currency+FormatNumber(sr.totalAmount)}</h5>
                                    <input type='hidden' name='totalAmount' value ={sr.totalAmount} />
                                    <input type='hidden' name='jwtToken' value ={Token} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
)}

            {salary&&salary.map((sr, id)=>
            <div key={id} className="row">
                <div className="col-sm-12">
                {sr.remarks!==''? <h6>Remarks :</h6>:''}
                    <p>{sr.remarks} </p>
                    <input type='hidden' name='remarks' value ={sr.remarks} />
                </div>
            </div>)}
        
        
</div>
    </div>

    <div className="row text-center">
        <div className="col-sm-12 invoice-btn-group text-center">
                <input type='hidden' name='salary' value ={JSON.stringify(salary, null, 2)} />
                <input type='hidden' name='payMonth' value ={payslip.payMonth} />
                <input type='hidden' name='staffCode' value ={payslip.staff.value} />
                <button type="submit" className="btn btn-primary btn-print-invoice m-b-10 btn-sm waves-effect waves-light m-r-20"><i className="fa fa-print"></i>  Print Slip</button>
        </div>
    </div>
</form>:''}


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Payslip) 