import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, Token, Currency, config} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, sort, formatGroupLabel, FormatNumber } from '../component/globalFunction'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
const UpdateStudentFees=()=>{
   
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
	const [errors, setErrors] = useState({});
	
const [feetype, setFeetype] =  useState([]);
    const [feecharge, setFeecharge] = useState([]);
	const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
	const [fees, setFees] = useState([]);
	const [title, setTitle] = useState('Fee Structure');
	const [feeStructure, setFeeStructure] = useState([]);
const [studentList, setStudentList] = useState([]);

const [payment, setPayment] =  useState([]);
const [studentFee, setStudentFee]=useState({
    studentClass:[],
	student:[],
	feecharge:[],
	classFee:[],
	classActualFee:[],
	remark:'',
	total:0
});

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
const [actualFee, setActualFee] =  useState([]);


const fetchFeecharge =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
    let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
    axios.post(url, fd, config).then(result=>setFeecharge(result.data))
}

const handleSelectFee = (option, action)=>{
	setErrors({...errors, feecharge:''})
	const fd = new FormData();
            fd.append('jwt', Token);
let url = ServerUrl + '/fetchField/tbl_class_fees/feesDetail/sectionID/'+studentFee.studentClass.value
axios.post(url, fd, config)
.then(result => {
	if(result.data.length!==0){

		let newFees = result.data.filter(item=>Number(item.price)>0)
		var total = newFees.reduce((initial, item)=>initial+Number(item.discount), 0)

	

var options = result.data

let list = [];
for (var i = 0, l = options.length; i < l; i++) {
	
	list.push({
		ID:options[i].ID,
		fees:options[i].fees,
		price:options[i].price,
		status:'false',
		discount:options[i].price,
		discountAmount:0
	})

}

setStudentFee({...studentFee, classFee:list, classActualFee:list, [action.name]: option, total:total})
	}

})
.catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
}



const getRealFee = (ID) =>{        
	const result = studentFee.classActualFee.filter(list=>list.ID===ID);
	const answer =result.length!==0?result[0].price:''
	return   answer

	}

	const createRow =()=>{
		const fees =JSON.parse(JSON.stringify(studentFee.classFee))
		let newItem ={ 
			ID: Math.random().toString(36).substr(2, 9),
			actualFees: "0",
			discount: "0",
			discountAmount: "0",
			fees: "",
			price: "0",
			status: "true",
		}
		setErrors({...errors, feeList:''})
		setStudentFee({...studentFee, classFee:fees.concat(newItem)})
	}

	const removeRowNew=(index)=>{
		const fees =JSON.parse(JSON.stringify(studentFee.classFee)) 
		
		if(fees.length>1){
	
		let newRow = fees.filter((_, i) => i !== index)
	
		let newFees = newRow.filter(item=>Number(item.price)>0)
	
		var total =newFees.reduce((initial, item)=>initial+Number(item.discount), 0)
	  
		setStudentFee({...studentFee, classFee:fees, total:total})
		}
	   }

	   
	const handleChangeNewEditFee =(index, e, ID)=>{
        
		const fees =JSON.parse(JSON.stringify(studentFee.classFee))   
	     
		const actualFees = getActualFee(ID);
		const value =e.target.value;
		
		
		fees[index].price = value;

		
  var discountAmount = fees[index].discountAmount
  let discount = Number(value)-Number(discountAmount);

  fees[index].discount = discount;
  fees[index].actualFees= actualFees;


		let newFees = fees.filter(item=>Number(item.price)>0)
		var total =newFees.reduce((initial, item)=>initial+Number(item.discount), 0)
	
	  setStudentFee({...studentFee, classFee:fees, total:total})
		  
   }



   const handleChangeNewType =(index, e, ID)=>{
	const fees =JSON.parse(JSON.stringify(studentFee.classFee))  

	const value =e.target.value;
	fees[index].ID = value;

	setStudentFee({...studentFee, classFee:fees})

 }

	const handleChangeNewFee =(index, e, ID)=>{
        
		const fees =JSON.parse(JSON.stringify(studentFee.classFee))   
	     
		const actualFees = getActualFee(ID);
		const value =e.target.value;
		
		fees[index].discountAmount = value;
		var price = fees[index].price
		let discount = Number(price)-Number(value);
	  
		fees[index].discount = discount;
		fees[index].actualFees= actualFees;


		let newFees = fees.filter(item=>Number(item.price)>0)
		var total =newFees.reduce((initial, item)=>initial+Number(item.discount), 0)
	
	  setStudentFee({...studentFee, classFee:fees, total:total})
		  
   }


   const handleChangeStatus =(index, e)=>{
        
    const fees =JSON.parse(JSON.stringify(studentFee.classFee))  
  fees[index].status = fees[index].status ==='true'? 'false':'true';
  let newFees = fees.filter(item=>item.status==='true')
  var total = newFees.reduce((initial, item)=>initial+Number(item.price), 0)

  setStudentFee({...studentFee, classFee:fees, total:total})
    
}



   const handleChangeRemark = event =>{
	const {name, value} = event.target
	setStudentFee({...studentFee, remark:value})
}

const handleAddClick =()=>{
	if(studentFee.studentClass.length===0){
		//Alerts('Error!', 'danger', 'Please select class')
		setErrors({...errors, studentClass:'Please select class'})
	}else if(studentFee.student.length===0){
		setErrors({...errors, student:'Please select student'})
	}else if(studentList.length===0){
		setErrors({...errors, student:'Please select student'})
	}else{
		let close =   document.getElementById('openAddDialog')
		close.click();
	}
}

const handleSaveFees =(event)=>{
	event.preventDefault()


if(studentFee.classFee.length===0){
	setErrors({...errors, feecharge:'There are no fee structure define for the selected class'})
}else if(studentFee.feecharge.length===0){
	setErrors({...errors, feecharge:'Please select fee charge'})
}else{


	var sList =[];
	sList.push({code:studentFee.student.value, admissionNumber:studentFee.student.label})
	
	
	const fd = new FormData();
            fd.append('jwt', Token);
   fd.append('classID', studentFee.studentClass.classID);
   fd.append('sectionID', studentFee.studentClass.value);
   fd.append('feecharge', studentFee.feecharge.value);
   fd.append('studentList', JSON.stringify(sList, null, 2));

	fd.append('classFee', JSON.stringify(studentFee.classFee, null, 2));
	fd.append('remark', studentFee.remark);
	fd.append('total', studentFee.total);
	setNotice({...notice,  isLoading: true}) 

		let url = ServerUrl+'/save_controller/tbl_student_fees'
	   axios.post(url, fd, config)
	   .then(response =>{

		 if (response.data.type ==='success') {
			   Alerts('Saved!', 'success', response.data.message)
		   }else if (response.data.type ==='info') {
			   Alerts('Update!', 'default', response.data.message)
		   }  else{
			   Alerts('Error!', 'danger', JSON.stringify(response.data))
	   }   
			   
	   })
	   .catch((error)=>{
		   Alerts('Error!', 'danger', JSON.stringify(error.message)) 
	   })
	   .finally(()=>{
		 setNotice({...notice, isLoading: false})  
		 fetchFees(studentFee.studentClass.value, studentFee.student.value)
		 fetchPayment(studentFee.student.value)
		 let close =   document.getElementById('closeAddDialog')
		close.click();
	   setStudentFee({...studentFee, classFee: [], classActualFee:[], feecharge:[], remark:'' });
	   }) 
}
}


		 const fetchStudent =(sectionID)=>{
			setNotice({...notice, isLoading: true}) 
			var sql ="SELECT admissionNumber, registerNumber, studentName, code from tbl_students where sectionID ='"+sectionID+"' and termination <>'Yes' and admissionStatus = 'Studying'"
		
			const fd = new FormData();
            fd.append('jwt', Token);
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller'
		axios.post(url, fd, config).then(result=>{
			
		setStudentList(result.data)
		})
		.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
		}).finally(()=>{
			setNotice({...notice, isLoading: false})
			
		})
		}
			

		const fetchPayment =(student)=>{
			setNotice({...notice, isLoading: true}) 
			var sql ="SELECT CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, h.code, h.amount,  h.receiptNo, h.status, h.paymentMode, h.paidBy, h.datePaid, h.remark from tbl_fees_charge f,  tbl_fees_payment_history h where h.feesCharge = f.code and  h.studentCode ='"+student+"'"
		
			const fd = new FormData();
            fd.append('jwt', Token);
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller'
		axios.post(url, fd, config).then(result=>{
			
			
		setPayment(result.data)
		})
		.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
		}).finally(()=>{
			setNotice({...notice, isLoading: false})
			
		})
		}
		const handleChange = event =>{
			 const {name, value} = event.target
		   setFeeStructure({...feeStructure, remark:value})
		}


		
const handleChangeFeeStatus =(index, e)=>{
        
    const fees =JSON.parse(JSON.stringify(feeStructure.structure))  
  fees[index].status = fees[index].status ==='true'? 'false':'true';
  let newFees = fees.filter(item=>item.status==='true')
  var total = newFees.reduce((initial, item)=>initial+Number(item.price), 0)

  
  setFeeStructure({...feeStructure, structure:fees, total:total})
 
    
}




 const handleChangeType =(index, e, ID)=>{
	const fees =JSON.parse(JSON.stringify(feeStructure.structure))  

	const value =e.target.value;
	fees[index].ID = value;

	setFeeStructure({...feeStructure, structure:fees})

 }


const handleChangeEditFee =(index, e, ID)=>{
	const actualFees = getActualFee(ID);

	const fees =JSON.parse(JSON.stringify(feeStructure.structure))   

   const value =e.target.value;
  fees[index].price = value;

  var discountAmount = fees[index].discountAmount
  let discount = Number(value)-Number(discountAmount);

  fees[index].discount = discount;
  fees[index].actualFees= actualFees;

  let newFees = fees.filter(item=>Number(item.price)>0)

  var total =newFees.reduce((initial, item)=>initial+Number(item.discount), 0)

setFeeStructure({...feeStructure, structure:fees, total:total})
	  
}

		const handleChangeFee =(index, e, ID)=>{
			const actualFees = getActualFee(ID);

			const fees =JSON.parse(JSON.stringify(feeStructure.structure))   
		
		   const value =e.target.value;
		  fees[index].discountAmount = value;
		
		  var price = fees[index].price
		  let discount = Number(price)-Number(value);
		
		  fees[index].discount = discount;
		  fees[index].actualFees= actualFees;
		
		  let newFees = fees.filter(item=>Number(item.price)>0)
		
		  var total =newFees.reduce((initial, item)=>initial+Number(item.discount), 0)
		
		setFeeStructure({...feeStructure, structure:fees, total:total})
		  	
	   }

	
	const fetchFeestructure =(row)=>{
		setNotice({...notice, isLoading: true}) 
		var sql ="Select feeStructure, remark from tbl_student_fees where code = '"+row.code+"'" ;
	
		const fd = new FormData();
		fd.append('jwt', Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	axios.post(url, fd, config).then(result=>{
		
		var item = JSON.parse(result.data[0].feeStructure)
		let newFees = item.filter(item=>Number(item.price)>0)
		var total = newFees.reduce((initial, item)=>initial+Number(item.discount), 0)
		

		setFeeStructure({total:total, structure:item, remark:result.data[0].remark, code:row.code})
		setTitle(row.fees)
	})
	.catch((error)=>{
	Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, isLoading: false})
		
	})
	}


		const  handleFetchClassfee =(sectionID)=>{
			const fd = new FormData();
            fd.append('jwt', Token);
			let url = ServerUrl + '/fetchField/tbl_class_fees/feesDetail/sectionID/'+sectionID
			axios.post(url, fd, config)
			.then(result => {
				setActualFee(result.data)
			})
			.catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
			 }




		const getActualFee = (ID) =>{        
            const result = actualFee && actualFee.filter(list=>list.ID===ID);
            const answer =result.length!==0?result[0].price:0
            return   answer
    
			}
			

		const fetchFees =(sectionID, student)=>{
			setNotice({...notice, isDataFetching: true}) 
			var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, s.code, s.payment_status, s.feeCharge, s.classID, s.sectionID, s.total  from tbl_fees_charge f, tbl_student_fees s where s.feeCharge = f.code and s.sectionID = '"+sectionID+"' and s.studentCode = '"+student+"' order by f.feesCharge" ;
		
			const fd = new FormData();
            fd.append('jwt', Token);
		fd.append("sql", sql);
		let url = ServerUrl+'/fetchBySql_controller'
		axios.post(url, fd, config).then(result=>{
			if(result.data.length!==0)
		setFees(result.data)
		})
		.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
		}).finally(()=>{
			setNotice({...notice, isDataFetching: false})
			
		})
		}

	
	 
		const handleSelect = (option, action)=>{
			setStudentFee({...studentFee, [action.name]: option, student:[]});
			setErrors({...errors, [action.name]:'' })
			fetchStudent(option.value);
			
			setPayment([])
			setFees([])
			setFeeStructure('')
			setTitle('Fee Structure')
         }
		 

		 const handleSelectStudent = (option, action)=>{
            setStudentFee({...studentFee, [action.name]: option});
			fetchFees(studentFee.studentClass.value, option.value)
			handleFetchClassfee(studentFee.studentClass.value)
			
			
			setErrors({...errors, [action.name]:'' })
			setFeeStructure('')
			setTitle('Fee Structure')
			fetchPayment(option.value)
         }

		


		 function handleSubmit(){  
    

			
			const fd = new FormData();
            fd.append('jwt', Token); 
			fd.append('code', feeStructure.code);
			fd.append('remark', feeStructure.remark);
			fd.append('total', feeStructure.total);
			fd.append('feeStructure', JSON.stringify(feeStructure.structure, null, 2));
			 
			setNotice({...notice, 
				isLoading: true}) 
		
				 let url = ServerUrl+'/update_controller/tbl_student_fees'
				axios.post(url, fd, config)
				.then(response =>{
		 if (response.data.type ==='info') {
						Alerts('Update!', 'default', response.data.message)
					}  else{
						Alerts('Error!', 'danger', JSON.stringify(response.data))
				}   
						
				})
				.catch((error)=>{
					Alerts('Error!', 'danger', JSON.stringify(error.message)) 
				})
				.finally(()=>{
					fetchFees(studentFee.studentClass.value, studentFee.student.value)
				  setNotice({...notice, isLoading: false})  
				  setFeeStructure('')
				setTitle('Fee Structure')
				})   
				  
		}

		 const handleDelete = (feeID)=>{  
			setNotice({...notice, 
			   isLoading: true}) 
			   let close =   document.getElementById('btnWarningDialog-'+feeID)
				  close.click();
				  const fd = new FormData();
				  fd.append('jwt', Token);
			   fd.append('code', studentFee.student.value)
				fd.append('feesCharge', feeID)
				fd.append('sectionID', studentFee.studentClass.value)

				let url = ServerUrl+'/update_controller/tbl_delete_fees'
			   axios.post(url, fd, config)
			   .then(response =>{
				if(response.data.type ==='info'){
				   Alerts('Info!', 'info', response.data.message)
					   } else{
				   Alerts('Error!', 'danger', JSON.stringify(response.data))
					   }   
			   })
			   .catch((error)=>{
				 Alerts('Error!', 'danger', error.message)
			   }).finally(()=>{
				   setNotice({...notice, 
					   isLoading: false
				   })
				   fetchFees(studentFee.studentClass.value, studentFee.student.value)
		 			fetchPayment(studentFee.student.value)
			   }) 
		}



		const handleDeletePayment = (code)=>{  
			setNotice({...notice, 
			   isLoading: true}) 
			   let close =   document.getElementById('btnWarningDialog-'+code)
				  close.click();

				const fd = new FormData();
				  fd.append('jwt', Token);
			   fd.append('studentCode', studentFee.student.value)
				fd.append('paymentCode', code)
				
				let url = ServerUrl+'/update_controller/delete_fees_payment'
			   axios.post(url, fd, config)
			   .then(response =>{
				if(response.data.type ==='info'){
				   Alerts('Info!', 'info', response.data.message)
					   } else{
				   Alerts('Error!', 'danger', JSON.stringify(response.data))
					   }   
			   })
			   .catch((error)=>{
				 Alerts('Error!', 'danger', error.message)
			   }).finally(()=>{
				   setNotice({...notice, 
					   isLoading: false
				   })
				   fetchFees(studentFee.studentClass.value, studentFee.student.value)
		 			fetchPayment(studentFee.student.value)
			   })  
		}



		 const tableStudentFeesHeader = [
			{dataField: 'code', text: 'Select', editable: false, formatter:(cell, row)=><div>
				

				<WarningModal  handleAction={()=>handleDelete(row.feeCharge)} mID={row.feeCharge} message='All associated payment with this fees will be deleted as well. Are you sure?' />

				<div className="btn-group dropdown-split-success ">
        <button type="button" className="btn btn-sm btn-outline-success dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-hand"></i>Action
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
          



		<form method='POST' action={ServerUrl+'/printout/student_class_fees.php?token='+userToken} target='_blank'>
		<input type='hidden' name='studentCode' value ={studentFee.student.value} />
		<input type='hidden' name='jwtToken' value ={Token} />
		<input type='hidden' name='feescharge' value ={row.fees} />

		<input type='hidden' name='feeCode' value ={row.code} />

		
		<button type="submit" style={{ cursor:'pointer' }} className="dropdown-item waves-effect waves-light"><i className='fa fa-print'></i> Preview</button>

		
</form>



            <a className="dropdown-item waves-effect waves-light" onClick={()=>fetchFeestructure(row)} href="#!"><i className="icofont icofont-edit-alt"></i> Edit Fees</a>



            <div className="dropdown-divider"></div>
          <a href="#!" data-toggle="modal" className="dropdown-item "  data-target={`#warningdialog-${row.feeCharge}`} title="Delete"><i className="fa fa-trash"></i>Delete Fees</a>
        </div>
    </div>
			</div>},
			{dataField: 'fees', text: 'Fees',  editable: false, formatter:(cell)=><span style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{cell}</span>},
			{dataField: 'total', text: 'Total ('+Currency+')', editable: false, formatter:(cell)=>FormatNumber(cell)}
			      
		 ];
	
		
	
		 const TableStudentFees=(props)=>{
		   
		const { SearchBar } = Search;
		
		const customTotal = (from, to, size) => (
		<span >&nbsp;Showing { from } to { to } of { size } items</span>
		);
		const options = {
		showTotal: true,
		paginationTotalRenderer: customTotal,
		sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
		{ text: 'All', value: props.data.length }]
		
		};
		return  <ToolkitProvider search columnToggle 
					keyField='code' data={ props.data } columns={ props.columns } >
						
						{
							props => (
							
							<div className="form-group">
	
									
									
									<div className="dt-buttons btn-group pull-right"> 
      <button onClick={handleAddClick} className="btn btn-outline-success waves-effect waves-light" >
            <i className="fa fa-plus"></i>Add New Fees </button>
			</div>

					<BootstrapTable
								{ ...props.baseProps }
											striped
										hover
										condensed
										noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
									pagination={paginationFactory(options) }
									
								/>
							</div>
							)
						}
	</ToolkitProvider>
	}
	

	const tablePaymentHeader = [
		
		{dataField: 'code', text: 'Action', formatter:(cell, row)=> <form method='POST' action={ServerUrl+'/printout/payment_receipt.php?token='+userToken} target='_blank'>
		
		<input type='hidden' name='slipcode' value ={cell} />
         <input type='hidden' name='jwtToken' value ={Token} />
		 <button type='submit' className="btn btn-info btn-sm"  title='Print' > <i className="fa fa-print"></i> </button>&nbsp;

		 
{row.paymentMode !=='Online - Paystack'?
		 <button type="button" className="btn btn-danger deletes btn-sm " data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="fa fa-trash"></i></button>:[]}
		 

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDeletePayment(row.code)} mID={row.code} /> 


		
		</form>},
		{dataField: 'receiptNo', text: 'Receipt No'},
		{dataField: 'fees', text: 'Fees'},
		{dataField: 'paymentMode', text: 'Mode'},
		{dataField: 'amount', text: 'Amount ('+Currency+')',  formatter:(cell)=>FormatNumber(cell)},
		{dataField: 'remark', text: 'Remark', formatter:(cell)=>cell!==''?<span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>:''},
		{dataField: 'datePaid', text: 'Date Paid', formatter:(cell)=>longDate(cell)},
		{dataField: 'paidBy', text: 'Paid By', formatter:(cell)=>cell}
	 ];

	

	 const TablePayment=(props)=>{
	   
	const { SearchBar } = Search;
	
	const customTotal = (from, to, size) => (
	<span >&nbsp;Showing { from } to { to } of { size } items</span>
	);
	const options = {
	showTotal: true,
	paginationTotalRenderer: customTotal,
	sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
	{ text: 'All', value: props.data.length }]
	
	};
	return  <ToolkitProvider search columnToggle 
				keyField='code' data={ props.data } columns={ props.columns } >
					
					{
						props => (
						
						<div className="form-group">

					<SearchBar
								 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
								
								<div className="dt-buttons btn-group pull-right"> 

			<form method='POST' action={ServerUrl+'/printout/student_payments_history.php?token='+userToken} target='_blank'>
		
				<input type='hidden' name='studentCode' value ={studentFee.student.value} />
				<input type='hidden' name='jwtToken' value ={Token} />

				<button type="submit" className="btn btn-outline-primary "  > <i className="fa fa-eye"></i>Print Preview
		</button>
		</form>
	</div>  
				<BootstrapTable
							{ ...props.baseProps }
										striped
									hover
									condensed
									noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
								pagination={paginationFactory(options) }
								
							/>
						</div>
						)
					}
</ToolkitProvider>
}

const createNewRow =()=>{
	const fees =JSON.parse(JSON.stringify(feeStructure.structure)) 
	let newItem ={ 
		ID: Math.random().toString(36).substr(2, 9),
		actualFees: "0",
		discount: "0",
		discountAmount: "0",
		fees: "",
		price: "0",
		status: "true",
	}
	setErrors({...errors, feeList:''})
setFeeStructure({...feeStructure, structure:fees.concat(newItem)})
}



const removeRow=(index)=>{
	const fees =JSON.parse(JSON.stringify(feeStructure.structure))
	
	if(fees.length>1){

	let newRow = fees.filter((_, i) => i !== index)

	let newFees = newRow.filter(item=>Number(item.price)>0)

	var total =newFees.reduce((initial, item)=>initial+Number(item.discount), 0)
  
  setFeeStructure({...feeStructure, structure:newRow, total:total})
	}
   }


   
const  handleFetchData =()=>{
    const fd = new FormData();
            fd.append('jwt', Token);
    let url = ServerUrl + '/fetch_controller/tbl_fee_type'
    setNotice({...notice,  isDataFetching: true });
    axios.post(url, fd, config)
    .then(result =>setFeetype(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false
        })
    })    
        }


		 useEffect(()=>{
			handleFetchData()
			fetchFeecharge()
		},[]);

        return ( <>
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Update Student Fees">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Update Student Fees</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <button data-toggle="modal" id='openAddDialog'   data-target='#addFees'  className='display-none'> </button>

				<div className="modal fade " id='addFees' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add fees to student</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">


			  <div className="row">
		    
            <section className="col-md-12">
            <div className="form-group">
         <label className="bold "> Fee Charge For <span style={{color:'red'}}>*</span></label>
         <Select  options={feecharge&&feecharge.map((list, idx)=> {
                               return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                             })
                        } 
                        getOptionLabel={option =>`${option.session}  ${option.label}`}       
onChange={handleSelectFee} className={errors.feecharge ? 'form-control form-control-danger' : ''} name="feecharge" value={studentFee.feecharge} styles={selectStyles} /> <span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>

            </div>
            </section>

			</div>


			  <div className="form-group row">
					
									
						<div className="col-md-12 ">
						
						{studentFee.classFee.length!==0?

						<table className="table ">
							<tr>
    <th><a onClick={createRow} href="#!"><i className="fa fa-plus"></i> Add</a></th>
                    <th>Fees Type </th>
					<th>Actual Amount </th>
                    <th>Discount </th>
                    <th>To Pay</th>
                </tr>
        <tbody >

{ studentFee.classFee.map((fee, idx)=>  <tr key={idx}>


<td> <button type="button" onClick={()=>removeRowNew(idx)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>

	<td>
   		<select onChange={(e)=>handleChangeNewType(idx, e, fee.ID)} id={'class'+idx}  name="ID[]" value={fee.ID} className="form-control">

                    <option value="" disabled>Select</option>

					{ feetype&&feetype.map((list, id)=> {
                            return <option key={id} value={list.ID}>{list.fees}</option>})}

                        </select> 
                        
       </td>
		<td > <input type="number" min="0" className="form-control" onChange={(e)=>handleChangeNewEditFee(idx, e, fee.ID)}  name="price[]" value={fee.price} />
                </td>

				
				<td >
                    <input type="number" min="0" className="form-control" onChange={(e)=>handleChangeNewFee(idx, e, fee.ID)}  name="discount[]" value={fee.discountAmount} />
                </td>

			 	<td><b>{FormatNumber(Number(fee.discount))} </b> </td> 

			</tr>)
			
			} 
				<tr>
				
						<th colSpan={4}>Amount To Pay </th>
						<th >{FormatNumber(studentFee.total)}</th>
					</tr>
					<tr>
			<td colSpan="4">

			<textarea  name="remark" className="form-control" placeholder="Remark" value={studentFee.remark} onChange={handleChangeRemark} rows="2" >{studentFee.remark}</textarea>
			</td>
			</tr>

				</tbody>
		</table>:''}

							</div>
				</div>

				
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeAddDialog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={handleSaveFees} className="btn btn-inverse">Add Fees Structure</button>
              </div>
          </div>
      </div>
  </div>





								<div className="row  ">
								<div className="col-sm-5">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Update Fee</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
										<div className="row">
										<section className="col-md-12">
										<div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} styles={selectStyles}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={studentFee.studentClass}  /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>	



			<div className="form-group">
						<label>Select Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            studentList.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} styles={selectStyles} name="student" className={errors.student ? 'form-control form-control-danger' : ''} value={studentFee.student}  /> 


<span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
										</section>
									
									</div>
										
							</div>
							</div>



	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Search Fees</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
		{notice.isDataFetching ? <TableLoader />:
        <TableStudentFees data={fees}  columns={tableStudentFeesHeader} />}
                </div>
            </div>
            </div> 
							</div>
		
	
	

	<div className="col-sm-7">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>{title}</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
		{feeStructure.length!==0  ?     <div className="row">
    
    <div className="col-md-12 table-responsive"> 
    
      <table className="table ">
	  <thead>
                <tr>
    <th><a onClick={createNewRow} href="#!"><i className="fa fa-plus"></i> Add</a></th>
                    <th>Fees Type </th>
					<th>Actual Amount </th>
                    <th>Discount </th>
                    <th>To Pay</th>
                </tr>
            </thead>

        <tbody >
{ feeStructure.structure.map((fee, idx)=> <tr key={idx}>

<td> <button type="button" onClick={()=>removeRow(idx)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>

	<td>
   		<select onChange={(e)=>handleChangeType(idx, e, fee.ID)} id={'class'+idx}  name="ID[]" value={fee.ID} className="form-control">

                    <option value="" disabled>Select</option>

					{ feetype&&feetype.map((list, id)=> {
                            return <option key={id} value={list.ID}>{list.fees}</option>})}

                        </select> 
                        
       </td>
		<td > <input type="number" min="0" className="form-control" onChange={(e)=>handleChangeEditFee(idx, e, fee.ID)}  name="price[]" value={fee.price} />
                </td>

				
				<td >
                    <input type="number" min="0" className="form-control" onChange={(e)=>handleChangeFee(idx, e, fee.ID)}  name="discount[]" value={fee.discountAmount} />
                </td>

			 	<td><b>{FormatNumber(Number(fee.discount))} </b> </td> 
			</tr>)
			
			}

			 <tr>
				
				<td colSpan={4}>Total Amount To Pay</td>
				<th  >{Currency+ FormatNumber(feeStructure.total)}</th>
			</tr>

				<tr>
			<td colSpan="5">

			<textarea  name="remark" className="form-control" placeholder="Remark" value={feeStructure.remark} onChange={handleChange} rows="2" >{feeStructure.remark}</textarea>
			</td>
			</tr> 

				</tbody>
              <tfoot>
				   <tr><td colSpan={4}>
				  <div className="pull-right">
	<button type="button" id="submit"  className="btn btn-inverse " onClick={handleSubmit}>Update Student Fees</button>
	</div></td>
				  </tr> 
			  </tfoot>
		</table>
        </div>
        </div>:""}
                </div>
            </div>
            </div> 
                </div>


                </div>


				<div className="row">

				<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>Paid List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
	
        <TablePayment data={payment}  columns={tablePaymentHeader} />
                </div>
            </div>
            </div> 
                </div></div>


          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(UpdateStudentFees) 