import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config} from '../component/include'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Link } from 'react-router-dom'

 const StudentMasterEntry =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
   
const [occupation, setOccupation]=useState([])
const [house, setHouse]=useState([])
const [document, setDocument]=useState([])
const [termination, setTermination]=useState([])
const [program, setProgram]=useState([])
const [club, setClub]=useState([])
const [position, setPosition]=useState([])
const [master, setMaster] = useState({
    occupation:'',
    house:'',
    program:'',
    termination:'',
    document:'',
    club:'',
    position:''
})
const [errors, setErrors] = useState({});
const [activeStep, setActiveStep] = useState(1);



const fetchOccupation =()=>{
    var sql ="SELECT occupation, document, house, club, position, terminationReason, program from  tbl_student_master where ID="+1;
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
if(result.data.length>0){
    setClub(JSON.parse(result.data[0].club))
    setPosition(JSON.parse(result.data[0].position))
setOccupation(JSON.parse(result.data[0].occupation))
setHouse(JSON.parse(result.data[0].house))
setDocument(JSON.parse(result.data[0].document))
setTermination(JSON.parse(result.data[0].terminationReason))
setProgram(JSON.parse(result.data[0].program))
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}


const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }

 const steps = [{title: 'Club'}, {title: 'Post'},{title: 'Occupation'},  {title: 'Qualification'},  {title: 'House'}, {title:'Suspension Reason'}]

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
        handleSubmit(false)
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])

    
const handleSubmit =(data=true)=>{
    
    setNotice({...notice,  isLoading: true}) 

        const fd = new FormData(); 
        fd.append('occupation', JSON.stringify(occupation))
        fd.append('program', JSON.stringify(program))
        fd.append('terminationReason', JSON.stringify(termination))
        fd.append('house', JSON.stringify(house))
        fd.append('document', JSON.stringify(document))
        fd.append('club', JSON.stringify(club))
        fd.append('position', JSON.stringify(position))
        fd.append('studentMaster', 'studentMaster')
        fd.append('jwt', Token)
         let url = ServerUrl+'/update_controller/tbl_student_master';
        axios.post(url, fd, config)
        .then(response =>{
            if(data==true){
            if (response.data.type ==='info'){
                Alerts('Saved!', 'info', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }  }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false}) 
        }) 
}


function submitOccupation(event){ 
    event.preventDefault();
    if(!master.occupation){
        setErrors({...errors, occupation:'Occupation field is Required'})
    }else{
    
        var code = 'o'+Math.random().toString(36).substr(2,9)
        setOccupation(prev=>{
             return [...prev, {code:code, occupationName:master.occupation}]
        })
        setMaster({...master, occupation:''})
    }
}

function submitPosition(event){ 
    event.preventDefault();
    if(!master.position){
        setErrors({...errors, position:'Position field is Required'})
    }else{
    
        var code = 'p'+Math.random().toString(36).substr(2,9)
        setPosition(prev=>{
             return [...prev, {code:code, positionName:master.position}]
        })
        setMaster({...master, position:''})
    }
}

function submitClub(event){ 
    event.preventDefault();
    if(!master.club){
        setErrors({...errors, club:'Club field is Required'})
    }else{
    
        var code = 'c'+Math.random().toString(36).substr(2,9)
        setClub(prev=>{
             return [...prev, {code:code, clubName:master.club}]
        })
        setMaster({...master, club:''})
    }
}

function submitDocument(event){  
    event.preventDefault();
    if(!master.document){
        setErrors({...errors, document:'Document field is Required'})
    }else{          

        var code = 'o'+Math.random().toString(36).substr(2,9)
        setDocument(prev=>{
             return [...prev, {code:code, documentName:master.document}]
        })
        setMaster({...master, document:''})
    }
}



function submitHouse(event){ 
    event.preventDefault();
    if(!master.house){
        setErrors({...errors, house:'House field is Required'})
    }else{       
        var code = 'o'+Math.random().toString(36).substr(2,9)
        setHouse(prev=>{
             return [...prev, {code:code, houseName:master.house}]
        })
        setMaster({...master, house:''})
    }
}


function submitProgram(event){ 
    event.preventDefault();
    if(!master.program){
        setErrors({...errors, program:'Program field is Required'})
    }else{           
        var code = 'o'+Math.random().toString(36).substr(2,9)
        setProgram(prev=>{
             return [...prev, {code:code, programName:master.program}]
        })
        setMaster({...master, program:''})
    } 
}



function submitTermination(event){ 
    event.preventDefault();
    if(!master.termination){
        setErrors({...errors, termination:'Termination reason is Required'})
    }else{            
        var code = 'o'+Math.random().toString(36).substr(2,9)
        setTermination(prev=>{
             return [...prev, {code:code, terminationName:master.termination}]
        })
        setMaster({...master, termination:''})
    }
}



    const handleChange = event =>{
        const {name, value} = event.target
       setMaster({...master, [name] : value });
       setErrors({...errors, [name]:''})
    }

    const handleDelete = (code, table)=>{ 
        if(table==='occupation'){
            setOccupation(occupation.filter(item=>item.code!==code))
        }else if(table==='program'){
            setProgram(program.filter(item=>item.code!==code))
        }else if(table==='document'){
            setDocument(document.filter(item=>item.code!==code))
        }else if(table==='club'){
            setClub(club.filter(item=>item.code!==code))
        }else if(table==='position'){
            setPosition(position.filter(item=>item.code!==code))
        }else if(table==='house'){
            setHouse(house.filter(item=>item.code!==code))
        }else{
            setTermination(termination.filter(item=>item.code!==code))
        }
    }

  
 const BasicTable=(props)=>{
    const handleUpdate=(column, newValue, code)=>{   
        
        var table = props.table

        if(table==='occupation'){
            var allData = occupation.filter(item=>item.code!==code)
            let edit = occupation.filter(item=>item.code===code)
                edit[0].occupationName = newValue
            setOccupation(edit.concat(allData))

        }else if(table==='program'){
           const allData = program.filter(item=>item.code!==code)
           let  edit = program.filter(item=>item.code===code)
                edit[0].programName = newValue
                setProgram(edit.concat(allData))

        }else if(table==='document'){
           const allData = document.filter(item=>item.code!==code)
           let  edit = document.filter(item=>item.code===code)
                edit[0].documentName = newValue
                setDocument(edit.concat(allData))
        }else if(table==='house'){
          const allData = house.filter(item=>item.code!==code)
           let  edit = house.filter(item=>item.code===code)
                edit[0].houseName = newValue
                setHouse(edit.concat(allData))
        }else if(table==='club'){
            const allData = club.filter(item=>item.code!==code)
             let  edit = club.filter(item=>item.code===code)
                  edit[0].clubName = newValue
                  setClub(edit.concat(allData))
          }else if(table==='position'){
            const allData = position.filter(item=>item.code!==code)
             let  edit = position.filter(item=>item.code===code)
                  edit[0].positionName = newValue
                  setPosition(edit.concat(allData))
          }else{
          const allData = termination.filter(item=>item.code!==code)
           let  edit = termination.filter(item=>item.code===code)
                edit[0].terminationName = newValue
                setTermination(edit.concat(allData))
        }
       
     } 


     const { SearchBar } = Search;
         
          const customTotal = (from, to, size) => (
             <span >&nbsp;Showing { from } to { to } of { size } items</span>
           );
        const options = {
            showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
        { text: 'All', value: props.data.length }]
           
          };
  
     return  <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.code);
                           
                          return '';
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }



    const tableOccupationHeader = [
       /*  {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger delete btn-sm" onClick={()=>handleDelete(row.code, 'occupation')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'occupationName', text: 'Occupation',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Occupation field is required'
              };
            }
            return true;
          } }     
     ];

     const tableDocumentHeader = [
        /* {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn delete btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'document')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'documentName', text: 'Document',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Document field is required'
              };
            }
            return true;
          } }     
     ];


     const tableHouseHeader = [
        /* {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'house')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'houseName', text: 'House',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'House field is required'
              };
            }
            return true;
          } }     
     ];

     const tableClubHeader = [
        /* {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'house')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'clubName', text: 'Club Name',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Club field is required'
              };
            }
            return true;
          } }     
     ];

     const tablePositionHeader = [
        /* {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'house')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'positionName', text: 'Post Name',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Position field is required'
              };
            }
            return true;
          } }     
     ];

     const tableProgramHeader = [
        /* {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'program')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'programName', text: 'Program',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Program field is required'
              };
            }
            return true;
          } }     
     ];
     
     const tableTerminationHeader = [
     /* {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'termination')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'terminationName', text: 'Termination Reason',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Termination Reason is required'
              };
            }
            return true;
          } }     
     ];



     useEffect(()=>{
        fetchOccupation()

    },[]);
        return (<> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

        <PageHeader title="Settings">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="/students/view_students"> Students</Link></li>        
                    <li className="breadcrumb-item"><a href="#!">Settings</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Settings</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>
            
        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="club">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                       <input type="text" placeholder='Enter club name' name='club' onChange={handleChange} value={master.club} className={errors.club ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitClub}>Add</button>
              </div>
            <span style={{color:'red'}}>{errors.club && errors.club}</span>
           </div>		
      </section>
          </div>
           </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={club} table={'club'} columns={tableClubHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
:  activeStep === 2 ? 
	  
      <div className="row setup-content" id="position">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12">

      <div className="form-group">
          <div className="input-group">
                      <input type="text" placeholder='Enter Position' name='position' onChange={handleChange} value={master.position} className={errors.position ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                     <button className="btn btn-inverse" type="button" onClick={submitPosition}>Add</button>
             </div>
           <span style={{color:'red'}}>{errors.position && errors.position}</span>
          </div>		
     </section>
         </div>
          </div>
                              
      </div>	
            <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12 table-responsive">
      {notice.isDataFetching ? <TableLoader />:
  <BasicTable data={position} table={'position'} columns={tablePositionHeader}  />} 
      </section>
              </div>
              </div>
      
      </div>	</div>
      
      </div>	
           
      </div>
   
</div>
:  activeStep === 3 ? 
<div className="row setup-content" id="occupation">
		    
            <div className="col-md-12">
     
            <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="row">
                                
            <section className="col-md-12">
     
            <div className="form-group">
                <div className="input-group">
                            <input type="text" placeholder='Enter Occupation' name='occupation' onChange={handleChange} value={master.occupation} className={errors.occupation ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                           <button className="btn btn-inverse" type="button" onClick={submitOccupation}>Add</button>
                   </div>
                 <span style={{color:'red'}}>{errors.occupation && errors.occupation}</span>
                </div>		
           </section>
               </div>
                </div>
                                    
            </div>	
                  <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="row">
                                
            <section className="col-md-12 table-responsive">
            {notice.isDataFetching ? <TableLoader />:
        <BasicTable data={occupation} table={'occupation'} columns={tableOccupationHeader}  />} 
            </section>
                    </div>
                    </div>
            
            </div>	</div>
            
            </div>	
                 
            </div>
         
     </div>

      :  activeStep === 4 ?  <div className="row setup-content" id="document">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12">

      <div className="form-group">
          <div className="input-group">
                      <input type="text" placeholder='Enter Document Title' name='document' onChange={handleChange} value={master.document} className={errors.document ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                     <button className="btn btn-inverse" type="button" onClick={submitDocument}>Add</button>
             </div>

              <span style={{color:'red'}}>{errors.document && errors.document}</span>
          </div>		
     </section>
         </div>
                              </div>
                              
      </div>	
            <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12 table-responsive">
      {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={document}  table={'document'} columns={tableDocumentHeader}  />} 
    </section>
              </div>
              </div>
      
      </div>	</div>
      
      </div>	
           
      </div>
   
</div>:activeStep ===5 ?
<div className="row setup-content" id="house">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                <input type="text" placeholder='Enter House/Caste Name' name='house' onChange={handleChange} value={master.house} className={errors.house ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitHouse}>Add</button>
              </div>
               <span style={{color:'red'}}>{errors.house && errors.house}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={house}  table={'house'} columns={tableHouseHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
/* activeStep === 4 ?  <div className="row setup-content" id="program">
		    
<div className="col-md-12">

<div className="card z-depth-0">

<div className="card-block">
<div className="card z-depth-0">

<div className="card-block">
<div className="row">
                    
<section className="col-md-12">

<div className="form-group">
    <div className="input-group">
                <input type="text" placeholder='Enter Program name' name='program' onChange={handleChange} value={master.program} className={errors.program ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
               <button className="btn btn-inverse" type="button" onClick={submitProgram}>Add</button>
       </div>

        <span style={{color:'red'}}>{errors.program && errors.program}</span>
    </div>		
</section>
   </div>
                        </div>
                        
</div>	
      <div className="card z-depth-0">

<div className="card-block">
<div className="row">
                    
<section className="col-md-12 table-responsive">
{notice.isDataFetching ? <TableLoader />:
   <BasicTable data={program}  table={'program'} columns={tableProgramHeader}  />} 
   </section>
        </div>
        </div>

</div>	</div>

</div>	
     
</div>

</div> */:
<div className="row setup-content" id="termination">
		    
            <div className="col-md-12">
     
            <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="row">
                                
            <section className="col-md-12">
     
            <div className="form-group">
                <div className="input-group">
                            <input type="text" placeholder='Enter Termination Reason' name='termination' onChange={handleChange} value={master.termination} className={errors.termination ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                           <button className="btn btn-inverse" type="button" onClick={submitTermination}>Add</button>
                   </div>
     
                    <span style={{color:'red'}}>{errors.termination && errors.termination}</span>
                </div>		
           </section>
               </div>
                                    </div>
                                    
            </div>	
                  <div className="card z-depth-0">
     
        <div className="card-block">
            <div className="row">
                                
            <section className="col-md-12 table-responsive">
            {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={termination}  table={'termination'} columns={tableTerminationHeader}  />} 
            </section>
                    </div>
                    </div>
            
            </div>	</div>
            
            
         
     </div>
	  </div>
                </div>}

                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(StudentMasterEntry) 