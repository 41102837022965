import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {config, ServerUrl, Token} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Select from 'react-select'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'

const ResultSession=()=>{


    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
       
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true); 
    const [errors, setErrors] = useState({});
    const [exams, setExams]=useState([])

    const [result, setResult]=useState({
        session:[],
        firstTerm:[],
        secondTerm:[],
        thirdTerm:[],

    })


    const [session, setSession] = useState([]);
    const [allResultSession, setAllResultSession]=useState([])



    const fetchExams =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_exam_type';
        axios.post(url, fd, config).then(result=>setExams(result.data))
    }

    const fetchAllResultSession =()=>{
        setNotice({...notice,  isDataFetching: true}) 
        var sql ="SELECT r.code, r.firstTerm, r.secondTerm, r.thirdTerm, r.sessionCode, s.startYear from tbl_result_session r, tbl_school_session s where r.sessionCode =s.ID " ;

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            setAllResultSession(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,  isDataFetching: false}) 
        })
    }

    
    const handleSelect = (option, action)=>{
        setResult({...result, [action.name]: option });
       setErrors({...errors, [action.name]:''})
    }



    const handleDelete =(code)=>{
		let close =   document.getElementById('btnWarningDialog-'+code)
		close.click();
		

		setNotice({...notice,  isLoading: true}) 

		const fd = new FormData();
		fd.append('ID', 'code')
		fd.append('data', code)
        fd.append('jwt', Token);
	let url = ServerUrl+'/delete_controller/tbl_result_session';
		  axios.post(url, fd, config)
		  .then(response =>{
		   if(response.data.type ==='success'){
			  Alerts('Saved!', 'default', response.data.message)
				  } else{
			  Alerts('Error!', 'danger', JSON.stringify(response.data))
				  }   
		  })
		  .catch((error)=>{
			Alerts('Error!', 'danger', error.message)
		  }).finally(()=>{
            fetchAllResultSession()
			  setNotice({...notice, 
				  isLoading: false
			  })
		  }) 
}





const handleSubmit = event =>{
    event.preventDefault();   
var errorMessage = [];


    if(result.firstTerm.length===0){
        var firstTerm ='Please select first term exam';
        errorMessage.push(firstTerm)
        setErrors({...errors, firstTerm:firstTerm})
    } 

    if(result.secondTerm.length===0){
        var secondTerm ='Please select second term exam';
        errorMessage.push(secondTerm)
        setErrors({...errors, secondTerm:secondTerm})
    } 


    if(result.thirdTerm.length===0){
        var thirdTerm ='Please select third term exam';
        errorMessage.push(thirdTerm)
        setErrors({...errors, thirdTerm:thirdTerm})
    } 


    if(errorMessage.length === 0 ){
       submit()
    }
    
}



function submit(){
        setNotice({...notice, isLoading: true})    
        
            const fd = new FormData(); 
           
           fd.append('sessionCode', result.session.value);
           fd.append('firstTerm', result.firstTerm.value);
           fd.append('secondTerm', result.secondTerm.value);
           fd.append('thirdTerm', result.thirdTerm.value);
           
           fd.append('code', Math.random().toString(36).substr(2,9));
           fd.append('jwt', Token)
         let url = ServerUrl+'/save_controller/tbl_result_session';
                  axios.post(url, fd, config)
            .then(response =>{
                if (response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                } else{
                    Alerts('Hey!', 'default', JSON.stringify(response.data))
            }
                    
            })
            .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
.finally(()=>{
    setNotice({...notice,    isLoading: false})
       fetchAllResultSession()
        setResult({
            session:[],
            firstTerm:[],
            secondTerm:[],
            thirdTerm:[],
        }) 
    })
        }



const getTermName =(code)=>{
const rs = exams.filter(item=>item.code===code)[0];
return rs.length!==0? rs['term']:'';

}


    const tableHeader = [
        
        {dataField: 'startYear', text: 'Session', editable: false}, 

        {dataField: 'firstTerm', editable: false, text: 'First Term', editor: {
            type: Type.DATE}, formatter: (cell)=>getTermName(cell)},

        {dataField: 'secondTerm', editable: false, text: 'Second Term', editor: {
            type: Type.DATE}, formatter: (cell)=>getTermName(cell)},

            {dataField: 'thirdTerm', editable: false, text: 'Third Term', editor: {
                type: Type.DATE}, formatter: (cell)=>getTermName(cell)},

        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div> 
            
        <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button>
         
        <WarningModal message='Once deleted, you will no be able to recover this record. Are you realy sure?' handleAction={()=>handleDelete(row.code)} mID={row.code} />  </div>, editable: false}
        
     ];

    
const TableRecord=(props)=>{

   

            const { SearchBar } = Search;
            
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='code' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                
                                <div className="form-group">
                                    <SearchBar
                                    { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                     
                                     
          
                        <BootstrapTable
                                    { ...props.baseProps }
                                                striped
                                            hover
                                            condensed
                                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                        pagination={paginationFactory(options) }
                                       
                                    />
                                </div>
                                )
                            }
        </ToolkitProvider>
        }


        const  handleFetchData =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetch_controller/tbl_school_session';
            axios.post(url, fd, config)
            .then(result => setSession(result.data))
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            }



            const menu  = useSelector(state => state.rootReducer.sidebar);
            useEffect(()=>{   
            //setIsAuthenticated(menu.includes(location.pathname)?true:false)          
          setTimeout(()=>{
            fetchAllResultSession()
          }, 3000)

           fetchExams()
           handleFetchData();
        },[]);

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Result Session Setup">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Result</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Session</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  ">
                            <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Assign Exam Type</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">

            <form method='post' onSubmit={handleSubmit}> 
		<fieldset>
				<div className="row">

				<section className="col-md-6">
                <div className="form-group">
         <label> Session<span style={{color:'red'}}>*</span></label>
         <Select  options={session&&session.map((list, id)=>{
             return {key:id, value:list.ID, label:list.startYear, startDate:list.startDate, endDate:list.endDate}
         })}    

 onChange={handleSelect} className={errors.session ? 'form-control form-control-danger' : ''} name="session" value={result.session} id='session'  />

 <span style={{color:'red'}}>{errors.session && errors.session}</span>
            </div>
            </section>


            <section className="col-md-6">
                <div className="form-group">
         <label> First Term Result<span style={{color:'red'}}>*</span></label>
         <Select  options={exams.map((list, id)=>{
             return {key:id, value:list.code, label:list.term, session:list.session}
         })} 
         getOptionLabel={option =>option.session + ' '+  option.label}               
 onChange={handleSelect} className={errors.firstTerm ? 'form-control form-control-danger' : ''} name="firstTerm" value={result.firstTerm} id='firstTerm'  />

 <span style={{color:'red'}}>{errors.firstTerm && errors.firstTerm}</span>
            </div>
            </section>



    <section className="col-md-6">
                <div className="form-group">
         <label> Second Term Result<span style={{color:'red'}}>*</span></label>
         <Select  options={exams.map((list, id)=>{
             return {key:id, value:list.code, label:list.term, session:list.session}
         })} 
         getOptionLabel={option =>option.session + ' '+  option.label}               
 onChange={handleSelect} className={errors.secondTerm ? 'form-control form-control-danger' : ''} name="secondTerm" value={result.secondTerm} id='secondTerm'  />

 <span style={{color:'red'}}>{errors.secondTerm && errors.secondTerm}</span>
            </div>
            </section>

            <section className="col-md-6">
                <div className="form-group">
         <label> Third Term Result<span style={{color:'red'}}>*</span></label>
         <Select  options={exams.map((list, id)=>{
             return {key:id, value:list.code, label:list.term, session:list.session}
         })} 
         getOptionLabel={option =>option.session + ' '+  option.label}               
 onChange={handleSelect} className={errors.thirdTerm ? 'form-control form-control-danger' : ''} name="thirdTerm" value={result.thirdTerm} id='thirdTerm'  />

 <span style={{color:'red'}}>{errors.thirdTerm && errors.thirdTerm}</span>
            </div>
            </section>


     
     
            </div>
        </fieldset>	
                                
	<footer className="pull-right">
	
	<button type="submit" className="btn btn-inverse " >Save Result Session</button>
	
								</footer>		

                                </form>
							</div>
							</div>
							</div>



                            </div>



                            <div className="row">
         <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>All Result Attendace</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">

        <div className="card-block">


<div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={allResultSession}  columns={tableHeader} />}
	
	</div></div>

</div></div>

</div>


                </div>
          {/* The Page Body close here */}
          
            </div>
            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ResultSession) 