import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, selectStyles} from '../component/include'
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, sort, formatGroupLabel, getResultHead, getBritishDate } from '../component/globalFunction'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'

 const ResultSetupUpdate =()=>{

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [exams, setExams]=useState([])
    const students  = useSelector(state => state.rootReducer.students);
   
    
    const [remainStudentsAssessment,  setRemainStudentsAssessment] = useState([]);
    const [data, setData] =  useState([]);
    const [remainSubjects, setRemainSubjects] = useState([]);
    const [classStudent, setClassStudent] = useState([]);
    const [classSubject, setClassSubject] = useState([]);
    const [remainStudents, setRemainStudents] = useState([]);

    const [standards, setStandards]=useState([])
    const [assessment, setAssessment]=useState([])

    const [resultSubject, setResultSubject]=useState([])
    const [settings, setSettings]=useState([])
    const [classTeacher, setClassTeacher] = useState([]);
    const [studentList, setStudentList]=useState([])
    const [assessmentList, setAssessmentList]=useState([])
    const [examType, setExamType] = useState({
        resultClass:[],
        resultTerm:[],
        missingStudents:[],
        studentClass:[],
        examterm:[],
        missingSubjects:[],
        assessmentClass:[],
        assessmentterm:[],
        missingStudentsAssessment:[],
    })


    const [errors, setErrors] = useState({});
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const staff  = useSelector(state => state.rootReducer.staff);
    
    
    const [allSubject, setAllSubject]=useState([]);


const [activeStep, setActiveStep] = useState(1);
const steps = [{title: 'Student Result'}, {title: 'Class Subject'}, {title: 'Assessment'}]

const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])




    const fetchExams =()=>{
        var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active'  group by r.examCode order by exam ASC" ;


const fd = new FormData();

fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    setExams(result.data)
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}


    const handleSelect = (option, action)=>{
        setExamType({...examType, [action.name]: option, missingStudents:[] });
        setStudentList([])
        setClassSubject([])
       setErrors({...errors, [action.name]:''})
    }

    const fetchSettings =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
        axios.post(url, fd, config).then(result=>{
            setSettings(result.data)
          
        })
    }
       

    
    const handleDeleteClassResult =async(code)=>{
        setNotice({...notice, isLoading: true}) 
        let close =   document.getElementById('btnWarningDialog-'+code)
        close.click();
        
        const fd2 = new FormData();
        const fd = new FormData();
        var sql = "Delete from tbl_exam_result where  examCode ='"+examType.resultTerm.value+"' and sectionID ='"+examType.resultClass.value+"' and studentCode ='"+code+"'"

        var sql2 = "Delete from tbl_result_remarks where  examType ='"+examType.resultTerm.value+"' and sectionID ='"+examType.resultClass.value+"' and studentCode ='"+code+"'"
     

            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)

            fd2.append("jwt", Token)
            fd2.append("sql", sql2)
            await  axios.post(url, fd, config)
           await  axios.post(url, fd, config).then(result =>{
               
            setNotice({...notice, isLoading: false}) 
                fetchStudentList()
                
            })
    }







    const fetchStudentList =()=>{
    
        let errors = {};   
       
        if(examType.resultClass.length===0){
            errors.resultClass ='Please select class';
        }   
        if(examType.resultTerm.length===0){
            errors.resultTerm ='Please select exam type';
        } 
        
         
        
        setErrors(errors)
    
        if(Object.keys(errors).length === 0){
    
            setNotice({...notice, isLoading: true}) 
    
            var sql ="SELECT  s.admissionNumber, s.registerNumber, s.status, r.dateCreate, s.studentName,  r.uniqueCode, r.studentCode as code, r.examDate, r.teacher, r.dateCreate, r.studentCode  from tbl_exam_result r, tbl_students s where  s.code = r.studentCode  and r.examCode ='"+examType.resultTerm.value+"' and r.sectionID ='"+examType.resultClass.value+"' group by r.studentCode order by s.studentName ASC";

    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0 && Array.isArray(result.data)){
            setStudentList(result.data)
            filterStudent(result.data)
        }else{
   setStudentList([])
   Alerts('Info!', 'default', 'Result not found for the selected class')
   }
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    })
        }
    }


    const fetchClassSubject =(sectionID)=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_class_subject/subjectDetails/sectionID/'+sectionID;
        axios.post(url, fd, config)
        .then(result =>{

            if(result.data.length!==0 && Array.isArray(result.data)){

            const subj = result.data
            var subjectList = [];
            for (var i = 0; i< subj.length;  i++) {
                subjectList.push(
                    {
                        subjectCode:subj[i].subjectID,
                        subjectName:getSubjectName(subj[i].subjectID, 'subjectName'),  
                        order:i+1,
                       status:true,
                        teacher:getSubjectName(subj[i].subjectID, 'teacher')
                    });
            }
            setClassSubject(subjectList)
        }
        })
    }

    const handleSelectStudent = (option, action)=>{
        setExamType({...examType, [action.name]: option });
       setErrors({...errors, [action.name]:''})
        fetchClassSubject(examType.resultClass.value)
        fetchClassTeaher(examType.resultClass.classID, examType.resultClass.value)
    }

   


    const fetchClassTeaher =(classID, sectionID)=>{
        var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
        setClassTeacher(newStaff)
    }




const filterStudent =(studentLists)=>{
const std = students
var newList = std.filter(function(objFromA) {
    return !studentLists.find(function(objFromB) {
      return objFromA.code === objFromB.code
    })
  })

setRemainStudents(newList)

}



const handleChangeStatus =(index, e)=>{
        
    const subj = JSON.parse(JSON.stringify(classSubject)) 
  subj[index].status = !subj[index].status;
  setErrors({...errors, missingStudents:''})
  setClassSubject(subj)
    
}



const submitResult =()=>{
    let errors = {};   
       
    
    var subj = classSubject.filter(item=>item.status===true);
    var std = examType.missingStudents;

    if(subj.length===0){
        errors.missingStudents ='Please select applicable subject';
    } 

    if(classTeacher.length===0){
        errors.missingStudents ='Please assign form tutor/class teacher to the selected class first';
    } 
    if(classSubject.length===0){
        errors.missingStudents ='Please assign subject to  class first';
    } 
    if(examType.missingStudents.length===0){
        errors.missingStudents ='Please select student';
    }  
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

        setNotice({...notice, isLoading: true}) 

       

    var subjectList = [];


    for (var i = 0; i < std.length; i++){
        for (var j = 0; j < subj.length; j++) {
            subjectList.push(
                {
                    code:Math.random().toString(36).substr(2,9),
                    admissionNumber:std[i].label, 
                    studentCode:std[i].value,
                    subjectCode:subj[j].subjectCode, 
                    subjectName:subj[j].subjectName,
                    order:subj[j].order,
                    classID:examType.resultClass.classID,
                    sectionID:examType.resultClass.value, 
                    examCode:examType.resultTerm.value,
                    maxMark:100,
                    teacher:subj[j].teacher,
                    examDate:studentList[0].examDate
                    
                });
        };
    }
       
    var remarkList = [];
    for (var i = 0; i < std.length; i++){
            remarkList.push(
                {
                    studentCode:std[i].value,
                    classID:examType.resultClass.classID,
                    sectionID:examType.resultClass.value, 
                    examCode:examType.resultTerm.value
                    
                });
    }

        var headName = getResultHead(examType.resultClass.classtype)==='Secondary'?
        String(settings.map((st)=>st.headofSecondary)): getResultHead(examType.resultClass.classtype)==='Primary'?
        String(settings.map((st)=>st.headofPrimary)): String(settings.map((st)=>st.headofNursery))
        
        

        const fd = new FormData(); 
        fd.append('uniqueCode', studentList[0].uniqueCode)
    fd.append('subjectList', JSON.stringify(subjectList, null, 2));
    fd.append('remarkList', JSON.stringify(remarkList, null, 2));
    fd.append('jwt', Token)

    fd.append('teacherName', studentList[0].teacher);
    fd.append('headName', headName)
    fd.append('resultHead', getResultHead(examType.resultClass.classtype))
    fd.append('headofSchool', String(settings.map((st)=>st.headofSchool)))


    let close =   document.getElementById('closeAddDialog')
        close.click();
   

    let url = ServerUrl+'/save_controller/tbl_exam_result';
            axios.post(url, fd, config)
        .then(response =>{
            if (response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
            }else if (response.data.type ==='info'){
                Alerts('Update!', 'info', response.data.message)
            }  else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }       
        })
        .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
    setNotice({...notice, isLoading: false})

    setClassSubject([])
    fetchStudentList()
   setExamType({
        ...examType,
        resultTerm:[],
        missingStudents:[],
       resultClass:[]
    })  
    }) 

}
}


const fetchStudents =()=>{

 var sql ="SELECT  s.admissionNumber, s.studentName, r.sectionID, r.classID,  r.studentCode as code from tbl_exam_result r, tbl_students s where  s.code = r.studentCode  and r.examCode ='"+examType.examterm.value+"' and r.sectionID ='"+examType.studentClass.value+"' group by r.studentCode order by s.studentName ASC";

    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0 && Array.isArray(result.data)){


    const subj = result.data
    var studentList = [];
    for (var i = 0; i< subj.length;  i++) {
        studentList.push(
            {
                code:subj[i].code,
                admissionNumber:subj[i].admissionNumber,  
                studentName:subj[i].studentName, 
                classID:subj[i].classID, 
                sectionID:subj[i].sectionID, 
                order:i+1,
               status:true,
               
            });
    } 
    setClassStudent(studentList)

        }
    })

     } 



const handleSelectResultSubject = (option, action)=>{
    setExamType({...examType, [action.name]: option, missingSubjects:[] });
    setResultSubject([])
    setClassStudent([])
   setErrors({...errors, [action.name]:''})
}



const filterSubject =(subjectLists)=>{
    const std = allSubject
    var newList = std.filter(function(objFromA) {
        return !subjectLists.find(function(objFromB) {
          return objFromA.code === objFromB.code
        })
      })
    
    setRemainSubjects(newList)
    
    }


const fetchSubjectList =()=>{
    
    let errors = {};   
   
    if(examType.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(examType.examterm.length===0){
        errors.examterm ='Please select exam type';
    } 
    
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT   r.uniqueCode, r.studentCode as code, r.subjectCode, r.examDate, r.teacher, r.displayOrder, r.dateCreate  from tbl_exam_result r where r.examCode ='"+examType.examterm.value+"' and r.sectionID ='"+examType.studentClass.value+"' group by r.subjectCode order by r.displayOrder ASC";

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0 && Array.isArray(result.data)){
        const subj = result.data
        var subjectList = [];
        for (var i = 0; i< subj.length;  i++) {
            subjectList.push(
                {
                    code:subj[i].subjectCode,
                    uniqueCode:subj[i].uniqueCode,
                    examDate:subj[i].examDate,
                    teacher:subj[i].teacher,
                    subjectName:getSubjectName(subj[i].subjectCode, 'subjectName'),  
                    dateCreate:subj[i].dateCreate,
                   
                });
        }
        fetchStudents()
        setResultSubject(subjectList)
        filterSubject(subjectList)
    }else{
        setResultSubject([])
Alerts('Info!', 'default', 'Subject not found for the selected result')
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
    }
}



const handleDeleteClassSubject =(code)=>{
    let close =   document.getElementById('btnWarningDialog-'+code)
    close.click();
    

    const fd = new FormData();
    var sql = "Delete from tbl_exam_result where  examCode ='"+examType.examterm.value+"' and sectionID ='"+examType.studentClass.value+"' and subjectCode ='"+code+"'"

        let url = ServerUrl+'/fetchBySql_controller'
        fd.append("jwt", Token)
        fd.append("sql", sql)
         axios.post(url, fd, config).then(result =>{
           
            fetchSubjectList()
        }) 
}



const  handleFetchAllSubject =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetch_controller/tbl_subject';
    axios.post(url, fd, config)
.then(result => setAllSubject(result.data))
.catch((error)=>{
    Alerts('Error!', 'danger', JSON.stringify(error.message)) 
}) }





const handleChangeStatusSubject =(index, e)=>{
        
    const subj = JSON.parse(JSON.stringify(classStudent)) 
  subj[index].status = !subj[index].status;
  setErrors({...errors, missingSubjects:''})
  setClassStudent(subj)
    
}






const handleSelectSubject = (option, action)=>{
    setExamType({...examType, [action.name]: option });
   setErrors({...errors, [action.name]:''})
}











const submitSubjectResult =()=>{
    let errors = {};   
       
    
    var std  = classStudent.filter(item=>item.status===true);
    var subj = examType.missingSubjects;

    if(std.length===0){
        errors.missingSubjects ='Please select applicable student';
    } 

   
    if(classStudent.length===0){
        errors.missingSubjects ='Please assign student to  class first';
    } 


    if(examType.missingSubjects.length===0){
        errors.missingSubjects ='Please select subject';
    }  
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

       setNotice({...notice, isLoading: true}) 

       

    var subjectList = [];


    for (var i = 0; i < std.length; i++){
        for (var j = 0; j < subj.length; j++) {
            subjectList.push(
                {
                    code:Math.random().toString(36).substr(2,9),
                    admissionNumber:std[i].admissionNumber, 
                    studentCode:std[i].code,
                    subjectCode:subj[j].value, 
                    order:std[i].order,
                    classID:examType.studentClass.classID,
                    sectionID:examType.studentClass.value, 
                    examCode:examType.examterm.value,
                    maxMark:100,
                    teacher:resultSubject[0].teacher,
                    examDate:resultSubject[0].examDate
                    
                });
        };
    }
       


        var headName = getResultHead(examType.studentClass.classtype)==='Secondary'?
        String(settings.map((st)=>st.headofSecondary)): getResultHead(examType.studentClass.classtype)==='Primary'?
        String(settings.map((st)=>st.headofPrimary)): String(settings.map((st)=>st.headofNursery))
        
        

        const fd = new FormData(); 
        fd.append('uniqueCode', resultSubject[0].uniqueCode)
    fd.append('subjectList', JSON.stringify(subjectList, null, 2));
    fd.append('jwt', Token)

    fd.append('teacherName', resultSubject[0].teacher);
    fd.append('headName', headName)
    fd.append('resultHead', getResultHead(examType.studentClass.classtype))
    fd.append('headofSchool', String(settings.map((st)=>st.headofSchool)))


    let close =   document.getElementById('closeAddSubjectDialog')
        close.click();

      let url = ServerUrl+'/save_controller/tbl_exam_result_subject';
        axios.post(url, fd, config)
        .then(response =>{
            if (response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
            }else if (response.data.type ==='info'){
                Alerts('Update!', 'info', response.data.message)
            }  else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }       
        })
        .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
    setNotice({...notice, isLoading: false})
    setResultSubject([])
    setClassSubject([])
    fetchStudentList()
   setExamType({
        ...examType,
        studentClass:[],
        examterm:[],
       missingSubjects:[]
    })  
    })   

}
}

const handleSelectClassExam = (option, action)=>{
    setExamType({...examType, [action.name]: option, missingStudentsAssessment:[] });
    setAssessmentList([])
    //setClassSubject([])
   setErrors({...errors, [action.name]:''})
}

const handleSelectStudentAssessment = (option, action)=>{
    setExamType({...examType, [action.name]: option });
   setErrors({...errors, [action.name]:''})
   
}

const filterStudentAssessment =(studentLists)=>{
    const std = students
    var newList = std.filter(function(objFromA) {
        return !studentLists.find(function(objFromB) {
          return objFromA.code === objFromB.code
        })
      })
    
    setRemainStudentsAssessment(newList)
    
    }

const fetchStudentAssessmentList =()=>{
    
    let errors = {};   
   
    if(examType.assessmentClass.length===0){
        errors.assessmentClass ='Please select class';
    }   
    if(examType.assessmentterm.length===0){
        errors.assessmentterm ='Please select exam type';
    } 
    
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

        setNotice({...notice, isLoading: true}) 

        var sql ="SELECT r.assessmentCode, s.admissionNumber, s.registerNumber, r.dateCreate, s.studentName,  r.uniqueCode, r.studentCode as code, r.dateCreate  from tbl_assessment_result r, tbl_students s where  s.code = r.studentCode  and r.examCode ='"+examType.assessmentterm.value+"' and r.sectionID ='"+examType.assessmentClass.value+"' group by r.studentCode order by s.studentName ASC";

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0 && Array.isArray(result.data)){
        setAssessmentList(result.data)
        filterStudentAssessment(result.data)
        handleSelectClass(examType.assessmentClass.value)
    }else{
setStudentList([])
Alerts('Info!', 'default', 'Result not found for the selected class')
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
    }
}

    const  handleFetchClassStandard =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_class_standards';
        axios.post(url, fd, config)
    .then(result =>   setData(result.data)
    )}

    const getAssessmentName =(code, field)=>{
        const result = standards.filter(item=>item.code===code)
        const answer = result.length!==0?result[0][field]:''
        return String(answer)
    }

    const handleSelectClass = (sectionID)=>{
       
       var standard = data.filter(item=>item.sectionID===sectionID)
       if(standard.length!==0){
           let ass = JSON.parse(standard[0].standardsDetails)
 
 var option = ass
    var response = []
    for (var i = 0; i < option.length; i++){
        response.push(
            {
                code:option[i].assessmentID,
                categoryCode:getAssessmentName(option[i].assessmentID, 'categoryCode'),
                assessmentName:getAssessmentName(option[i].assessmentID, 'assesmentName')

            })
    } 

    setAssessment(response)
        
       }else{
       setAssessment([]) 
       }
    }



    const fetchStandards =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_standards_setting/assessment/ID/1';
        axios.post(url, fd, config)
        .then(result =>setStandards(result.data.sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1)))
    }
    














    const handleDeleteClassAssessment =(code)=>{
        let close =   document.getElementById('btnWarningDialog-'+code)
        close.click();
        
    
        const fd = new FormData();
        var sql = "Delete from tbl_assessment_result where  examCode ='"+examType.assessmentterm.value+"' and sectionID ='"+examType.assessmentClass.value+"' and studentCode ='"+code+"'"
     let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)
             axios.post(url, fd, config).then(result =>{
                fetchStudentAssessmentList() 
            })
    }






    

    

    
  
    const submitResultAssessment =()=>{
        let errors = {};   
           
        
        var subj = assessment
        var std = examType.missingStudentsAssessment;
    
    
        if(examType.missingStudentsAssessment.length===0){
            errors.missingStudents ='Please select student';
        }  
        
        setErrors(errors)
    
        if(Object.keys(errors).length === 0){
    
            setNotice({...notice, isLoading: true}) 
    
           
        var assList = [];
        for (var i = 0; i < std.length; i++){
            for (var j = 0; j < subj.length; j++) {
                assList.push(
                    {
                        code:Math.random().toString(36).substr(2,9),
                        studentCode:std[i].value,
                        assessmentCode:subj[j].code,
                        grade:'',
                        classID:examType.assessmentClass.classID,
                        sectionID:examType.assessmentClass.value, 
                        examCode:examType.assessmentterm.value
                        
                    });
            };
        }   
    
        let close =   document.getElementById('closeAssDialog')
        close.click();   
        const fd = new FormData(); 

        fd.append('uniqueCode', assessmentList[0].uniqueCode)
    fd.append('assessmentList', JSON.stringify(assList, null, 2));
    fd.append('jwt', Token)

    let url = ServerUrl+'/save_controller/tbl_assessment_result';
          axios.post(url, fd, config)
        .then(response =>{
            if (response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
            }else if (response.data.type ==='info'){
                Alerts('Update!', 'info', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }       
        })
            .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
        .finally(()=>{
        setNotice({...notice, isLoading: false})
        fetchStudentAssessmentList()
       setExamType({
            ...examType,
            assessmentClass:[],
            missingStudentsAssessment:[],
           assessmentterm:[]
        })  
        })  
    
    }
    }

const getSubjectName = (code, field) =>{        
    const result = allSubject&&allSubject.filter(list=>list.code === code);
    return   String(result.map(c=>c[field]));
    }

    


const TableRecord=(props)=>{

   
            const { SearchBar } = Search;
            
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='code' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                
                                <div className="form-group">
                                    <SearchBar
                                    { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                     
                                     <div className="btn-group pull-right">
  
  <button type="button" className="btn btn-info"  data-toggle="modal" id='openAddDialog'   data-target='#addAssessment' >
  <i className="fa fa-plus"></i> Add Student To  Assessment </button>
  
  </div> 
          
                        <BootstrapTable
                                    { ...props.baseProps }
                                                striped
                                            hover
                                            condensed
                                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                        pagination={paginationFactory(options) }
                                       
                                    />
                                </div>
                                )
                            }
        </ToolkitProvider>
        }

       






         const TableExamSubject=(props)=>{
           
        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                    <div className="btn-group pull-right">
      
      <button type="button" className="btn btn-success" data-toggle="modal" id='openAddDialog'   data-target='#addSubject'>
      <i className="fa fa-plus"></i> Add Subject To Result </button>
      
      </div>         
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                   
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }


    const TableStudentList=(props)=>{
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData(); 
                fd.append('newValue', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
                fd.append('jwt', Token)
                let url = ServerUrl+'/updateCustom_controller/tbl_exam_type'
                axios.post(url, fd, config)
                //.then(result => console.log(result.data))
                .then()
                .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            } 

            
    
    const { SearchBar } = Search;
    
    const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } items</span>
    );
    const options = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
    
    };
    return  <ToolkitProvider search columnToggle 
                keyField='code' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        
                        <div className="form-group">
                            <SearchBar
                            { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                
                                <div className="btn-group pull-right">
  
  <button type="button" className="btn btn-info"  data-toggle="modal" id='openAddDialog'   data-target='#addStudent' >
  <i className="fa fa-plus"></i> Add Student To Result </button>
  
  </div>         
                <BootstrapTable
                            { ...props.baseProps }
                            striped
                            hover
                            condensed
                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                        pagination={paginationFactory(options) }
                        cellEdit={ cellEditFactory({
                        mode: 'dbclick',
                        blurToSave: true,
                        autoSelectText: true,
                beforeSaveCell: (oldValue, newValue, row, column) => {
                if(oldValue !==newValue){
                handleUpdate(column, newValue, row.ID);
                
                return true;
                }
                
            }
                        }) }
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}

const tableResultHeader = [
            
    {dataField: 'admissionNumber', text: 'Admission No',  editable: false},

        {dataField: 'studentName', text: 'Name',  editable: false, formatter:(cell, row)=>cell},

        {dataField: 'dateCreate', text: 'Creation Date',  sort: true, editable: false, formatter: (cell)=>longDate(cell)},

        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
        <WarningModal  handleAction={()=>handleDeleteClassResult(row.code)} mID={row.code} message='The student result for this exam will be deleted. Are you realy sure?' />
        <button type="button" className="btn btn-danger  delete btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="fa fa-trash"></i> </button>
        
        </div>, editable: false},        
     ]



     const tableResultSubjectHeader = [
            
      
            {dataField: 'subjectName', text: 'Subject',  editable: false, formatter:(cell, row)=>cell},
            {dataField: 'dateCreate', text: 'Creation Date',  sort: true, editable: false, formatter: (cell)=>getBritishDate(cell)},
    
            {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
            <WarningModal  handleAction={()=>handleDeleteClassSubject(row.code)} mID={row.code} message='The subject result for this exam will be deleted. Are you realy sure?' />
            <button type="button" className="btn btn-danger delete btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="fa fa-trash"></i> </button>
            
            </div>, editable: false},        
         ]


        
const tableResultAssessmentHeader = [
            
    {dataField: 'admissionNumber', text: 'Admission No',  editable: false},

        {dataField: 'studentName', text: 'Name',  editable: false, formatter:(cell, row)=>cell},
        {dataField: 'dateCreate', text: 'Creation Date',  sort: true, editable: false, formatter: (cell)=>longDate(cell)},

        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
        <WarningModal  handleAction={()=>handleDeleteClassAssessment(row.code)} mID={row.code} message='The student assessment for this exam will be deleted. Are you realy sure?' />
        <button type="button" className="btn btn-danger  delete btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} title="Delete"><i className="fa fa-trash"></i> </button>
        
        </div>, editable: false},        
     ]

       

      
    
    




   

        
     


        
const  handleSubmitResultSettings = event =>{
    event.preventDefault();

    setNotice({...notice, isLoading: true})  

        const fd = new FormData(); 
        fd.append('settings', JSON.stringify(settings))
        fd.append('jwt', Token);
         let url = ServerUrl+'/update_controller/tbl_result_slip';
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='info'){
                Alerts('Updated!', 'default', response.data.message)
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                fetchSettings()
                  setNotice({...notice,  isLoading: false  })
                  setActiveStep(1)
              })   
}

    



         


const menu  = useSelector(state => state.rootReducer.sidebar);
useEffect(()=>{   
//setIsAuthenticated(menu.includes(location.pathname)?true:false)  
        fetchStandards()
        fetchExams()
        handleFetchAllSubject()
       handleFetchClassStandard()
       fetchSettings();
    },[]);
  


        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
        <PageHeader title="Update Class Result">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Update Class Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                    
<div className="modal fade " id='addSubject' tabIndex="-1" role="dialog">
<div className="modal-dialog modal-lg" role="document">
<div className="modal-content ">
<div className="modal-header">
  <h6 className="modal-title"><i className='fa fa-info'></i> Add Subject To Result</h6>
  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div className="modal-body">


<div className="row">

<section className="col-md-12">
<div className="form-group">
<label className="bold "> Select Subject <span style={{color:'red'}}>*</span></label>
<Select isMulti
            options={
                remainSubjects&&remainSubjects.map((list, idx)=> {
                return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                })} 
                getOptionLabel={option =>`${option.label} - ${option.abr}`}

onChange={handleSelectSubject} className={errors.missingSubjects? 'form-control form-control-danger' : ''} styles={selectStyles} name="missingSubjects" value={examType.missingSubjects}  /> <span style={{color:'red'}}>{errors.missingSubjects && errors.missingSubjects}</span>


</div>
</section>

</div>


<div className="form-group row">
    <div className="col-sm-2">
            
    </div>
                    
        <div className="col-sm-10 ">

        {classStudent.length!==0?

        <table className="table ">
<tbody >

{ classStudent.map((std, idx)=> {
return   <tr key={idx}>
    
<td>{std.admissionNumber}</td>
<td>{std.studentName}</td>


<td>

<div className="checkbox-color checkbox-primary">
<input id={"stat"+idx}  name="status[]" type="checkbox"  onChange={(e)=>handleChangeStatusSubject(idx, e)}  checked={std.status} />
<label htmlFor={"stat"+idx}>Applicable</label>
</div>


</td>
</tr>
})

} 

</tbody>
</table>:''}

            </div>
</div>
<span style={{color:'red'}}>{errors.missingSubjects && errors.missingSubjects}</span>

</div>
<div className="modal-footer">
  <button type="button"  id='closeAddSubjectDialog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
  <button type="button" onClick={submitSubjectResult}className="btn btn-inverse">Create Result</button>
</div>
</div>
</div>
</div>









<div className="modal fade " id='addStudent' tabIndex="-1" role="dialog">
<div className="modal-dialog modal-lg" role="document">
<div className="modal-content ">
<div className="modal-header">
  <h6 className="modal-title"><i className='fa fa-info'></i> Add Student To Result</h6>
  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div className="modal-body">


<div className="row">

<section className="col-md-12">
<div className="form-group">
<label className="bold "> Select Student <span style={{color:'red'}}>*</span></label>
<Select isMulti
 options={
       remainStudents&&remainStudents.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} className={errors.missingStudents ? 'form-control form-control-danger' : ''} styles={selectStyles} name="missingStudents" value={examType.missingStudents}  /> <span style={{color:'red'}}>{errors.missingStudents && errors.missingStudents}</span>


</div>
</section>

</div>


<div className="form-group row">
    <div className="col-sm-2">
            
    </div>
                    
        <div className="col-sm-10 ">

        {classSubject.length!==0?

        <table className="table ">
<tbody >

{ classSubject.map((subj, idx)=> {
return   <tr key={idx}>
<td>{subj.subjectName}</td>


<td>

<div className="checkbox-color checkbox-primary">
<input id={"status"+idx}  name="status[]" type="checkbox"  onChange={(e)=>handleChangeStatus(idx, e)}  checked={subj.status} />
<label htmlFor={"status"+idx}>Applicable</label>
</div>


</td>
</tr>
})

} 

</tbody>
</table>:''}

            </div>
</div>
<span style={{color:'red'}}>{errors.missingStudents && errors.missingStudents}</span>

</div>
<div className="modal-footer">
  <button type="button"  id='closeAddDialog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
  <button type="button" onClick={submitResult}className="btn btn-inverse">Create Result</button>
</div>
</div>
</div>
</div>






<div className="modal fade " id='addAssessment' tabIndex="-1" role="dialog">
<div className="modal-dialog modal-lg" role="document">
<div className="modal-content ">
<div className="modal-header">
  <h6 className="modal-title"><i className='fa fa-info'></i> Add Student Assessment To Result</h6>
  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div className="modal-body">


<div className="row">

<section className="col-md-12">
<div className="form-group">
<label className="bold "> Select Student <span style={{color:'red'}}>*</span></label>
<Select isMulti
 options={ 
       remainStudentsAssessment&&remainStudentsAssessment.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudentAssessment} className={errors.missingStudentsAssessment? 'form-control form-control-danger' : ''} styles={selectStyles} name="missingStudentsAssessment" value={examType.missingStudentsAssessment}  /> <span style={{color:'red'}}>{errors.missingStudentsAssessment && errors.missingStudentsAssessment}</span>


</div>
</section>

</div>


<div className="form-group row">
    <div className="col-sm-2">
            
    </div>
                    
        <div className="col-sm-10 ">

        {assessment.length!==0?

        <table className="table ">
<tbody >

{ assessment.map((ass, idx)=> {
return   <tr key={idx}>
<td>{ass.assessmentName}</td>


</tr>
})

} 

</tbody>
</table>:''}

            </div>
</div>
<span style={{color:'red'}}>{errors.missingStudentsAssessment && errors.missingStudentsAssessment}</span>

</div>
<div className="modal-footer">
  <button type="button"  id='closeAssDialog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
  <button type="button" onClick={submitResultAssessment}className="btn btn-inverse">Create Result</button>
</div>
</div>
</div>
</div>


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Class Result</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	  <div className="row setup-content" id="resultList">
    

<div className="col-md-12">

<div className="card z-depth-0">

<div className="card-block">
<div className="card z-depth-0">


<div className="card-block">

<div className="row">
  <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type<span style={{color:'red'}}>*</span></label>
         <Select  options={exams.map((list, id)=>{
             return {key:id, value:list.examCode, label:list.exam}
         })}               
 onChange={handleSelect} className={errors.resultTerm ? 'form-control form-control-danger' : ''} name="resultTerm" value={examType.resultTerm}  />
 <span style={{color:'red'}}>{errors.resultTerm && errors.resultTerm}</span>
            </div>
     
     </section>

     <section className="col-md-6">
         <div className="form-group">
				<label > Select Class</label><span style={{color:'red'}}>*</span>
				<Select  options={
                        schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.resultClass? 'form-control form-control-danger' : ''} name="resultClass" value={examType.resultClass} /> 
<span style={{color:'red'}}>{errors.resultClass && errors.resultClass}</span>
										
			</div>
     
     </section>
     

        <div className="col-md-12 "> 
     <button type="button" id='btnCreate'  className="btn btn-inverse waves-effect pull-right" onClick={fetchStudentList}  > <i className="fa fa-graduation-cap"></i> Display Student</button>
     </div>




     </div>
  
     
</div></div>


    </div>

{examType.resultTerm.length!==0 &&studentList.length!==0?
    <div className="card-block">
       <div className="card z-depth-0">

       <div className="card-block">
      
  <div className="row">
                           
       <section className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableStudentList data={studentList}  columns={tableResultHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>
       </div>:''}




    </div>
                               
       </div>	

       
       </div>
    
    :activeStep==2?
<div className="row setup-content" id="extras">
		    

            

       <div className="col-md-12">

       <div className="card z-depth-0">





       <div className="card-block">
<div className="card z-depth-0">


<div className="card-block">

<div className="row">
  <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type<span style={{color:'red'}}>*</span></label>
         <Select   options={exams.map((list, id)=>{
             return {key:id, value:list.examCode, label:list.exam}
         })}              
 onChange={handleSelectResultSubject} className={errors.examterm ? 'form-control form-control-danger' : ''} name="examterm" value={examType.examterm}  />
 <span style={{color:'red'}}>{errors.examterm && errors.examterm}</span>
            </div>
     
     </section>

     <section className="col-md-6">
         <div className="form-group">
				<label > Select Class</label><span style={{color:'red'}}>*</span>
				<Select  options={
                        schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectResultSubject}  className={errors.studentClass? 'form-control form-control-danger' : ''} name="studentClass" value={examType.studentClass} /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
										
			</div>
     
     </section>
     

        <div className="col-md-12 "> 
     <button type="button" id='btnCreate'  className="btn btn-danger waves-effect pull-right" onClick={fetchSubjectList}  > <i className="fa fa-book"></i> Display Subjects</button>
     </div>




     </div>
  
     
</div></div>


    </div>

       {resultSubject.length!==0?
       <div className="card-block">
       <div className="card z-depth-0">

       <div className="card-block">
      
  <div className="row">
                           
       <section className="col-md-12 table-responsive">
       
       {notice.isDataFetching ? <TableLoader />:
   <TableExamSubject data={resultSubject}  columns={tableResultSubjectHeader}  />} 
      

       </section>
               </div>
               </div>
       
       </div>
       </div>:''}


       </div></div>
    
</div>:

<div className="row setup-content" >
		    

            

            <div className="col-md-12">
     
            <div className="card z-depth-0">
     
     
     
     
     
            <div className="card-block">
     <div className="card z-depth-0">
     
     
     <div className="card-block">
     
     <div className="row">
       <section className="col-md-6">
                <div className="form-group">
              <label> Exam Type<span style={{color:'red'}}>*</span></label>
              <Select  options={exams.map((list, id)=>{
             return {key:id, value:list.examCode, label:list.exam}
         })}               
      onChange={handleSelectClassExam} className={errors.assessmentterm ? 'form-control form-control-danger' : ''} name="assessmentterm" value={examType.assessmentterm}  />
      <span style={{color:'red'}}>{errors.assessmentterm && errors.assessmentterm}</span>
                 </div>
          
          </section>
     
          <section className="col-md-6">
              <div className="form-group">
                     <label > Select Class</label><span style={{color:'red'}}>*</span>
                     <Select  options={
                             schoolClass.map((list, idx)=> {
                                 return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                     return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                 }) }
                               })
                                  
                             } 
                             formatGroupLabel={formatGroupLabel}
     onChange={handleSelectClassExam}  className={errors.assessmentClass? 'form-control form-control-danger' : ''} name="assessmentClass" value={examType.assessmentClass} /> 
     <span style={{color:'red'}}>{errors.assessmentClass && errors.assessmentClass}</span>
                                             
                 </div>
          
          </section>
          
     
             <div className="col-md-12 "> 
          <button type="button" id='btnCreate'  className="btn btn-inverse waves-effect pull-right" onClick={fetchStudentAssessmentList}  > <i className="fa fa-graduation-cap"></i> Display Student</button>
          </div>
     
     
     
     
          </div>
       
          
     </div></div>
     
     
         </div>
         
           
{examType.assessmentClass.length!==0 &&assessmentList.length!==0?
    <div className="card-block">
       <div className="card z-depth-0">

       <div className="card-block">
      
  <div className="row">
                           
       <section className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={assessmentList}  columns={tableResultAssessmentHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>
       </div>:''}
     
     
     
                 
            </div></div>
         
     </div>}

     <button onClick={activeStep === steps.length ? handleSubmitResultSettings: handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Setup </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ResultSetupUpdate) 